import { useListContext } from "react-admin";

interface InlineListProps {
  source: string;
}

export const InlineList: React.FC<InlineListProps> = ({ source }) => {
  const { data } = useListContext();

  return <>{data?.map((record) => record[source]).join(", ")}</>;
};
