import { Box, Tooltip } from "@mui/material";
import { orange, red, purple } from "@mui/material/colors";
import { useRecordContext } from "react-admin";

export const AlertCodeField: React.FC<{ label?: string }> = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  const data = codeToLabel[record.code];

  const component = (
    <Box
      sx={{
        backgroundColor: data ? data.color + "52" : undefined,
        color: data ? data.color : undefined,
        borderRadius: 1,
        textAlign: "center",
        fontWeight: "bold",
        paddingX: 1,
        paddingY: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "min-content",
        whiteSpace: "nowrap",
        opacity: record.timeEnd ? 0.5 : 1,
      }}
    >
      {data?.label || record.code}
    </Box>
  );

  if (record.message) {
    return <Tooltip title={record.message}>{component}</Tooltip>;
  }

  return component;
};

export const codeToLabel: Record<
  string,
  { label: string; color: string; usedInIncidents?: boolean }
> = {
  "target-temperature": {
    label: "Target Temperature",
    color: "#2196f3",
  },
  "msg-frequency": {
    label: "Lost Connection",
    color: "#607d8b",
    usedInIncidents: true,
  },
  "lost-power": {
    label: "Lost Power",
    color: "#9e9e9e",
    usedInIncidents: true,
  },
  "no-heating": {
    label: "Heating Failure",
    color: "#673ab7",
    usedInIncidents: true,
  },
  "broken-probe": {
    label: "Broken Probe",
    color: "#03a9f4",
    usedInIncidents: true,
  },
  "legionellose-schedule": { label: "Legionellose Schedule", color: "#4caf50" },
  "legionellose-setpoint": { label: "Legionellose SetPoint", color: "#3f51b5" },
  "legionellose-observation": {
    label: "Thermostat mécanique trop bas",
    color: "#00bcd4",
    usedInIncidents: true,
  },
  "electrical-issue": {
    label: "Problème électrique partiel",
    color: orange["500"],
    usedInIncidents: true,
  },
  "relay-power-ko": {
    label: "Relay Power KO",
    color: orange["500"],
    usedInIncidents: true,
  },
  "trigger-alert": { label: "Trigger Alert", color: "#e91e63" },
  legionellose: {
    label: "Legionellose",
    color: "#795548",
  },
  "valve-obstruction": { label: "Valve Obstruction", color: "#00bcd4" },
  "heating-alert": { label: "Heating Alert", color: "#009688" },
  "water-leakage": { label: "Water Leakage", color: "#607d8b" },
  "water-leakage-1": { label: "Water Leakage 1", color: "#607d8b" },
  "water-leakage-2": { label: "Water Leakage 2", color: "#9e9e9e" },
  "detected-mode-change": {
    label: "Detected Mode Change",
    color: "#3f51b5",
  },
  hp_hc_not_found: { label: "HP/HC not found", color: "#e91e63" },
  "hp-hc-not-found": { label: "HP/HC not found", color: "#e91e63" },
  "cooling-speed": { label: "Cooling Speed", color: "#009688" },
  "low-temperature": { label: "Low temperature", color: "#009688" },
  switch: {
    label: "Bypass device button switched on",
    color: "#03a9f4",
    usedInIncidents: true,
  },
  "too-many-reboots": {
    label: "Too many Reboots",
    color: red["500"],
    usedInIncidents: true,
  },
  "discomfort-alert": {
    label: "Discomfort",
    color: purple["500"],
    usedInIncidents: false,
  },
};
