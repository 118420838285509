import { ReferenceOneField } from "react-admin";
import { AlertCodeField } from "./AlertCodeField";

export const LatestCriticalAlert: React.FC<{ label?: string }> = () => {
  return (
    <ReferenceOneField
      reference="alerts"
      target="deviceId"
      filter={{
        critical: true,
        timeEnd: { $exists: false },
      }}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <AlertCodeField />
    </ReferenceOneField>
  );
};
