import ClearIcon from "@mui/icons-material/Clear";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useURLFilter } from "./useURLFilter";

interface DateRangeFilterProps {
  source: string;
  label?: string;
  categories: {
    value: any;
    label: string;
  }[];
}

export const EnumFilter: React.FC<DateRangeFilterProps> = ({
  source,
  label,
  categories,
}) => {
  const [value, setFilters] = useURLFilter(source);

  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        value={JSON.stringify(value) ?? ""}
        label={label}
        placeholder={label}
        size="small"
        sx={{ minWidth: 200 }}
        onChange={(event) => setFilters(JSON.parse(event.target.value))}
        endAdornment={
          <IconButton
            sx={{ display: value ? "" : "none" }}
            onClick={() => setFilters(undefined)}
          >
            <ClearIcon />
          </IconButton>
        }
      >
        {categories.map((category, index) => (
          <MenuItem key={index} value={JSON.stringify(category.value)}>
            {category.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
