import { Typography } from "@mui/material";
import { FunctionField, ReferenceField, TextField } from "react-admin";
import type { TextFieldProps } from "react-admin";
import type { Project } from "../type";

export default function ProjectNameField(props: TextFieldProps<Project>) {
  return (
    <FunctionField<Project>
      render={(record) =>
        record.deploymentMaintenanceProviderId ? (
          <>
            <TextField source="name" {...props} />
            <ReferenceField
              reference="maintenance-providers"
              source="deploymentMaintenanceProviderId"
              link={false}
            >
              {" ("}
              <TextField source="name" {...props} />)
            </ReferenceField>
          </>
        ) : (
          <Typography variant={props.variant || "body2"}>
            {record.name}
          </Typography>
        )
      }
    />
  );
}
