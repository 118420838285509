import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import type { Instruction } from "../data/charts/schedule-series";

dayjs.extend(weekday);

export const ScheduleList: React.FC<{ instructions: Instruction[] }> = ({
  instructions,
}) => {
  return (
    <ul>
      {instructions.map((instruction, index) => (
        <li key={index}>
          {instruction.time.weekDay === "*"
            ? "Everyday"
            : dayjs()
                .weekday(instruction.time.weekDay as number)
                .format("dddd")}{" "}
          {instruction.time.hour.toString().padStart(2, "0")}:
          {instruction.time.minute.toString().padStart(2, "0")}:{" "}
          {instruction.setPoint}°C ±{(instruction.hysteresis / 10).toFixed(1)}°C
        </li>
      ))}
    </ul>
  );
};
