import { Datagrid, EditButton, List, TextField } from "react-admin";
import { MaintenanceSpreadsheetField } from "./fields/MaintenanceSpreadsheetField";

export const MaintenanceProviderList = () => {
  return (
    <List perPage={100} hasCreate>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <MaintenanceSpreadsheetField label="Maintenance Spreadsheet" />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};
