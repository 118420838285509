import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  List,
  ReferenceField,
  ReferenceManyCount,
  ShowButton,
  TextField,
} from "react-admin";

export const DeviceConfigPersonaList = () => (
  <List perPage={100} hasCreate>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" label="Creation Date" />
      <ReferenceManyCount
        label="Usage"
        reference="end-users"
        target="deviceConfigPersona"
      />
      <ReferenceField
        label="Comfort upgrade persona"
        reference="device-config-personas"
        source="comfortUpgradePersonaId"
      />
      <ShowButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
