import { Box, Card, Typography } from "@mui/material";
import { createElement } from "react";
import type { FC, ReactNode } from "react";

interface Props {
  icon: FC<any>;
  color: string;
  data: {
    label: string;
    value: string;
    after?: ReactNode;
    display?: boolean;
  }[];
  topRightContent?: ReactNode;
  actions?: ReactNode;
}

const CardWithIcon = ({
  icon,
  data,
  color,
  topRightContent,
  actions,
}: Props) => (
  <Card
    sx={{
      display: "flex",
      position: "relative",
      height: "100%",
    }}
  >
    {topRightContent && (
      <Box position="absolute" top={0} right={0}>
        {topRightContent}
      </Box>
    )}
    <Box
      sx={{
        padding: "16px",
        backgroundColor: color + "44",
        color: color,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {createElement(icon, { fontSize: "large" })}
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        {data
          .filter((field) => field.display !== false)
          .map((field) => (
            <Typography
              color="textSecondary"
              variant="caption"
              key={field.label}
            >
              <span style={{ color: "black", fontWeight: "bold" }}>
                {field.label}:{" "}
              </span>
              {field.value}
              {field.after}
            </Typography>
          ))}
      </Box>
      {actions && (
        <Box
          sx={{
            paddingBottom: 1,
            paddingRight: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {actions}
        </Box>
      )}
    </Box>
  </Card>
);

export default CardWithIcon;
