import { Alert, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { useRecordContext } from "react-admin";
import { useQuery } from "react-query";
import { dataProvider } from "../../../../../providers/data";
import type { Device } from "../../../types";

export const SavingsTable = () => {
  const record = useRecordContext<Device>();

  const { data } = useQuery({
    queryFn: () => dataProvider.getSavingsSummary(record.id as string),
    queryKey: ["savings", record.id],
    enabled: Boolean(record),
  });

  if (!data) return null;

  const refLabel = data.reference.slot
    ? `${dayjs(data.reference.slot.start).format("DD/MM/YYYY")} → ${dayjs(
        data.reference.slot.end ?? undefined
      ).format("DD/MM/YYYY")}`
    : "";

  const pilotLabel = data.pilot?.slot
    ? `${dayjs(data.pilot.slot.start).format("DD/MM/YYYY")} → Today`
    : "";

  const pilotPresenceRate =
    data.pilot &&
    1 -
      (data.pilot.absenceCount + data.pilot.inconsistentCount) /
        data.pilot.dayCount;
  const observationPresenceRate =
    data.reference.dayCount &&
    1 -
      (data.reference.absenceCount + data.reference.inconsistentCount) /
        data.reference.dayCount;

  return (
    <>
      <Typography variant="h5" marginTop={4}>
        Savings summary
      </Typography>
      {observationPresenceRate < 0.3 && (
        <Alert severity="warning">
          Observation presence rate is low (
          {observationPresenceRate?.toFixed(2)}) and might explain aberrant
          values
        </Alert>
      )}
      {pilotPresenceRate !== null &&
        pilotPresenceRate !== undefined &&
        pilotPresenceRate < 0.3 && (
          <Alert severity="warning">
            Pilot presence rate is low ({pilotPresenceRate?.toFixed(2)}) and
            might explain aberrant values
          </Alert>
        )}
      {data.reference.dayCount > data.reference.processableDayCount && (
        <Alert severity="warning">
          Observation contains long absence periods (
          {data.reference.dayCount - data.reference.processableDayCount} days)
          that were ignored for savings computation
        </Alert>
      )}
      {data.pilot && data.pilot.dayCount > data.pilot.processableDayCount && (
        <Alert severity="warning">
          Pilot contains long absence periods (
          {data.pilot.dayCount - data.pilot.processableDayCount} days) that were
          ignored for savings computation
        </Alert>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">
              Observation
              <br /> {refLabel}
            </TableCell>
            <TableCell align="right">
              Pilot
              <br /> {pilotLabel}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <HighlightedCell>Daily Consumption (kWh/day)*</HighlightedCell>
            <HighlightedCell align="right">
              {data.reference.dailyConsumptionInKWh}
            </HighlightedCell>
            <HighlightedCell align="right">
              {data.pilot?.dailyConsumptionInKWh ?? "N/A"}
            </HighlightedCell>
          </TableRow>
          <TableRow>
            <HighlightedCell align="right">Savings</HighlightedCell>
            <HighlightedCell align="right"></HighlightedCell>
            <HighlightedCell align="right">
              {data.savings?.ratio ?? "N/A"}
            </HighlightedCell>
          </TableRow>
          <TableRow>
            <HighlightedCell align="right">Annual Savings**</HighlightedCell>
            <HighlightedCell align="right"></HighlightedCell>
            <HighlightedCell align="right">
              {data.savings?.annual ?? "N/A"}
            </HighlightedCell>
          </TableRow>
          <TableRow>
            <SecondaryCell>Raw Daily Consumption (kWh/day)</SecondaryCell>
            <SecondaryCell align="right">
              {data.reference.rawDailyConsumptionInKWh}
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.pilot?.rawDailyConsumptionInKWh ?? "N/A"}
            </SecondaryCell>
          </TableRow>
          <TableRow>
            <SecondaryCell>
              Daily Consumption with season normalisation (kWh/day)
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.reference.annualisedDailyConsumptionInKWh}
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.pilot?.annualisedDailyConsumptionInKWh ?? "N/A"}
            </SecondaryCell>
          </TableRow>
          <TableRow>
            <SecondaryCell align="right">Days</SecondaryCell>
            <SecondaryCell align="right">
              {data.reference.dayCount}
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.pilot?.dayCount ?? 0}
            </SecondaryCell>
          </TableRow>
          <TableRow>
            <SecondaryCell align="right">Absences</SecondaryCell>
            <SecondaryCell align="right">
              {data.reference.absenceCount}
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.pilot?.absenceCount ?? 0}
            </SecondaryCell>
          </TableRow>
          <TableRow>
            <SecondaryCell align="right">Inconsistent days</SecondaryCell>
            <SecondaryCell align="right">
              {data.reference.inconsistentCount}
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.pilot?.inconsistentCount ?? 0}
            </SecondaryCell>
          </TableRow>
          <TableRow>
            <SecondaryCell>
              Processable days (used for savings computation)
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.reference.processableDayCount}
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.pilot?.processableDayCount ?? "N/A"}
            </SecondaryCell>
          </TableRow>
          <TableRow>
            <SecondaryCell align="right">
              Average daily data coverage (hours)
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.reference.averageDailyDataCoverageInHours?.toFixed(2)}
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.pilot?.averageDailyDataCoverageInHours?.toFixed(2) ?? "N/A"}
            </SecondaryCell>
          </TableRow>
          <TableRow>
            <SecondaryCell align="right">
              Average daily inconsistent data coverage (minutes)
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.reference.averageDailyInconsistentDataInMinutes}
            </SecondaryCell>
            <SecondaryCell align="right">
              {data.pilot?.averageDailyInconsistentDataInMinutes ?? "N/A"}
            </SecondaryCell>
          </TableRow>
        </TableBody>
      </Table>
      <i>
        * comparable values to serve savings computations, see "Raw Daily
        Consumption" for real values
      </i>
      <i>** tarif bleu EDF: {data.kWhPriceInEuro?.toFixed(4)}€</i>
    </>
  );
};

const HighlightedCell = (props: any) => (
  <TableCell
    align="right"
    sx={{
      backgroundColor: "#1470ef",
      color: "#ffffff",
      fontWeight: "bold",
    }}
    {...props}
  />
);

const SecondaryCell = (props: any) => (
  <TableCell
    align="right"
    sx={{
      paddingTop: 1,
      paddingBottom: 1,
      color: "#607d8b",
    }}
    {...props}
  />
);
