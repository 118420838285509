import { defaultTheme } from "react-admin";

export const elaxTheme = {
  ...defaultTheme,
  palette: {
    background: {
      default: "#fafafb",
    },
    secondary: {
      light: "#6ec6ff",
      main: "#1470ef",
      dark: "#0069c0",
      contrastText: "#fff",
      danger: "#f44336",
    },
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined" as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined" as const,
      },
    },
  },
};
