import { SelectInput, useGetList } from "react-admin";
import type { SelectInputProps } from "react-admin";

export const FirmwareVersionFilter: React.FC<SelectInputProps> = (props) => {
  // ReferenceInput cannot be used here as it would generate
  // a query /firmwares?filter={id: ["2.11.1"]} when selection
  // is done. "2.11.1" is not a valide mongo id
  const { data: firmwares, isLoading } = useGetList("firmwares", {
    pagination: { perPage: 1000, page: 1 },
    sort: { field: "createdAt", order: "DESC" },
  });

  return (
    <SelectInput
      {...props}
      isLoading={isLoading}
      choices={
        firmwares?.map((firmware) => ({
          id: firmware.version,
          name: firmware.version,
        })) || []
      }
      fullWidth
    />
  );
};
