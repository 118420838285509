import { Divider } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { ConsumptionExport } from "../../../actions/ConsumptionExport";
import {
  ActionsMenu,
  DeleteAction,
  EditAction,
} from "../../../layout/actions-menu";
import { AssignFirmware } from "../../devices/bulk-actions/AssignFirmware";
import { ChangePilotConfig } from "../../devices/bulk-actions/edit-device-config";
import { ExportDeviceFrames } from "../../devices/show/actions/ExportDeviceFrames";

export const GroupActions = () => {
  return (
    <ActionsMenu>
      <EditAction />
      <DeleteAction />
      <Divider />
      <ExportDeviceFrames />
      <IfCanAccess resource="groups.actions" action="use">
        <ConsumptionExport />
      </IfCanAccess>
      <Divider />
      <ChangePilotConfig />
      <IfCanAccess resource="groups.actions" action="use">
        <AssignFirmware />
      </IfCanAccess>
    </ActionsMenu>
  );
};
