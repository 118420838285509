import HistoryIcon from "@mui/icons-material/History";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import type { IconButtonProps } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useRecordContext } from "react-admin";
import type { Except } from "type-fest";
import { EditionHistory } from "../../activity-logs/EditionHistory";
import {
  interventionPriorityColors,
  interventionPriorityLabels,
} from "../../intervention/types";
import { incidentActorLabels } from "../types";
import type { Incident } from "../types";

export const IncidentHistory: React.FC<Except<IconButtonProps, "onClick">> = (
  props
) => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext<Incident>();

  if (!record) {
    return null;
  }

  return (
    <>
      <IconButton {...props} onClick={() => setOpen(true)}>
        <HistoryIcon />
      </IconButton>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
        <DialogTitle>Incident History</DialogTitle>
        <DialogContent>
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            {[...record.events].reverse().map((event, i) => {
              const actor = incidentActorLabels[event.assignedTo];
              return (
                <TimelineItem key={i}>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    {dayjs(event.datetime).format("YYYY-MM-DD HH:mm")}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot></TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Chip
                      label={actor?.label}
                      sx={{
                        backgroundColor: actor?.color["100"],
                        color: actor?.color["800"],
                        borderRadius: 1,
                        m: 0.5,
                        height: 22,
                      }}
                    />
                    {event.priority != undefined && (
                      <Chip
                        label={interventionPriorityLabels[event.priority]}
                        sx={{
                          backgroundColor:
                            interventionPriorityColors[event.priority]["100"],
                          color:
                            interventionPriorityColors[event.priority]["800"],
                          borderRadius: 1,
                          m: 0.5,
                          height: 22,
                        }}
                      />
                    )}

                    <Typography>
                      {event.action} {event.comment ?? ""}{" "}
                      {event.origin ? `(origine: ${event.origin})` : ""}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>

          <EditionHistory />
        </DialogContent>
      </Dialog>
    </>
  );
};
