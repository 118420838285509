import { SingleFieldList } from "react-admin";
import type { ChipFieldProps } from "react-admin";
import TagField from "./TagField";

interface TagArrayFieldProps {
  label?: string;
  TagFieldProps?: ChipFieldProps;
}

export default function TagArrayField({ TagFieldProps }: TagArrayFieldProps) {
  return (
    <SingleFieldList linkType={false}>
      <TagField {...TagFieldProps} />
    </SingleFieldList>
  );
}
