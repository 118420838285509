import { useQuery } from "react-query";
import { dataProvider } from "../../../../../../providers/data";

export const useDemandResponseOccurences = (
  deviceId: string | undefined,
  startDate: Date,
  endDate: Date
) => {
  const { data } = useQuery({
    queryFn: () =>
      dataProvider.getDemandResponses(deviceId!, startDate, endDate),
    queryKey: [`devices/${deviceId}/demand-responses`, deviceId],
    enabled: !!deviceId,
  });

  return data;
};
