import { Menu, MenuItem, styled } from "@mui/material";
import { useState } from "react";
import { HistoryItemType, historyItemColor } from "../../../history";
import { energyPalette, useDailyEnergyTimeline } from "./daily-energy";
import { coveragePalette, useDataCoverageTimeline } from "./data-coverage";
import { useHistoryTimeline } from "./history";

export enum Track {
  ENERGY = "Daily Energy",
  DATA_COVERAGE = "Data Coverage",
  HISTORY = "History Events",
  NONE = "Previous Navigation",
}

const sampleColors: Record<Track, string> = {
  [Track.ENERGY]: energyPalette[3],
  [Track.HISTORY]: historyItemColor[HistoryItemType.ALERT],
  [Track.DATA_COVERAGE]: coveragePalette[3],
  [Track.NONE]: "black",
};

interface TrackSelectorProps {
  value: Track;
  onChange: (value: Track) => void;
}

export const TrackSelector: React.FC<TrackSelectorProps> = ({
  value,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Anchor
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{ backgroundColor: sampleColors[value] }}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.values(Track).map((track) => (
          <MenuItem
            key={track}
            onClick={() => {
              onChange(track);
              handleClose();
            }}
            selected={track === value}
          >
            {track}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const useTimelineData = (track: Track) => {
  const energies = useDailyEnergyTimeline({ enabled: track === Track.ENERGY });
  const coverage = useDataCoverageTimeline({
    enabled: track === Track.DATA_COVERAGE,
  });
  const events = useHistoryTimeline({ enabled: track === Track.HISTORY });

  if (track === Track.ENERGY) {
    return energies ?? {};
  }
  if (track === Track.HISTORY) {
    return events ?? {};
  }

  if (track === Track.DATA_COVERAGE) {
    return coverage ?? {};
  }

  return {};
};

const Anchor = styled("div")`
  width: 18px;
  height: 18px;
  border: solid 1px black;
  margin: 2px;
  border-radius: 3px;
  position: absolute;
  top: 0;
  right: 0;
`;
