import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVert from "@mui/icons-material/MoreVert";
import { IconButton, Menu } from "@mui/material";
import type { ReactNode } from "react";
import { Button, TopToolbar } from "react-admin";
import { RecordDetails } from "../../resources/activity-logs/RecordDetails";
import { ActionMenuStateProvider, useActionMenuState } from "./context";

export {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "./ActionDialog";
export { ActionMenuItem } from "./ActionMenuItem";
export { ConfirmActionMenuItem } from "./ConfirmActionMenuItem";
export { CreateAction, DeleteAction, EditAction } from "./RecordActions";

export const ActionsMenu: React.FC<{ children: ReactNode[] | ReactNode }> = ({
  children,
}) => {
  return (
    <TopToolbar>
      <RecordDetails />
      <ActionMenuStateProvider>
        <ActionsMenuTrigger />
        <ActionsMenuPanel>{children}</ActionsMenuPanel>
      </ActionMenuStateProvider>
    </TopToolbar>
  );
};

export const IconActionMenu: React.FC<{
  children: ReactNode[] | ReactNode;
}> = ({ children }) => {
  return (
    <ActionMenuStateProvider>
      <IconActionsMenuTrigger />
      <ActionsMenuPanel>{children}</ActionsMenuPanel>
    </ActionMenuStateProvider>
  );
};

const IconActionsMenuTrigger: React.FC = () => {
  const [, setAnchorEl] = useActionMenuState();

  return (
    <IconButton
      size="small"
      onClick={(event) => {
        setAnchorEl(event.currentTarget);
      }}
    >
      <MoreVert />
    </IconButton>
  );
};

const ActionsMenuTrigger: React.FC = () => {
  const [, setAnchorEl] = useActionMenuState();

  return (
    <Button
      size="small"
      variant="contained"
      onClick={(event) => {
        setAnchorEl(event.currentTarget);
      }}
      endIcon={<KeyboardArrowDownIcon />}
      label="Actions"
    />
  );
};

const ActionsMenuPanel: React.FC<{ children: ReactNode[] | ReactNode }> = ({
  children,
}) => {
  const [anchorEl, setAnchorEl] = useActionMenuState();

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      onKeyDownCapture={(e) => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ width: 600 }}
    >
      {children}
    </Menu>
  );
};
