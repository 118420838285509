import ConsumptionIcon from "@mui/icons-material/ElectricBolt";
import { ActionMenuItem } from "../layout/actions-menu";
import { dataProvider } from "../providers/data";
import { useExport } from "./useExport";

export const ConsumptionExport: React.FC = () => {
  const { mutate } = useExport(dataProvider.generateConsumptionReport);

  return (
    <ActionMenuItem
      label="Export Consumption"
      icon={<ConsumptionIcon />}
      onClick={mutate}
    />
  );
};
