import OKIcon from "@mui/icons-material/CheckCircle";
import TimerIcon from "@mui/icons-material/Timer";
import { Box } from "@mui/material";
import { useRecordContext } from "react-admin";

export const AlertStatusField: React.FC<{ label?: string }> = () => {
  const alert = useRecordContext();

  const isOngoing = !alert.timeEnd;

  return (
    <Box
      sx={{
        backgroundColor: isOngoing ? "#ff980052" : "#8bc34a52",
        color: isOngoing ? "#ff9800" : "#8bc34a",
        borderRadius: 1,
        textAlign: "center",
        fontWeight: "bold",
        paddingX: 1,
        paddingY: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "min-content",
      }}
    >
      {isOngoing ? (
        <>
          <TimerIcon fontSize="small" />
          ONGOING
        </>
      ) : (
        <>
          <OKIcon fontSize="small" />
          SOLVED
        </>
      )}
    </Box>
  );
};
