import { Grid } from "@mui/material";
import { ContractType } from "./widgets/ContractType";
import { DevicesState } from "./widgets/DevicesState";
import { HealthRisk } from "./widgets/HealthRisk";
import { HeatersState } from "./widgets/HeaterState";
import { MetabaseQuestion } from "./widgets/MetabaseQuestion";

export const DevicesDashboard: React.FC<{ filter: any }> = ({ filter }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ContractType filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <HeatersState filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <DevicesState filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <HealthRisk filter={filter ?? {}} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetabaseQuestion questionId={282} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetabaseQuestion questionId={292} />
      </Grid>
    </Grid>
  );
};
