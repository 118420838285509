import { EnumField } from "../../../fields/EnumField";
import { interventionStatusColors, interventionStatusLabels } from "../types";

export const InterventionStatusField: React.FC<{ label?: string }> = ({
  label,
}) => (
  <EnumField
    label={label || "Status"}
    source="status"
    colors={interventionStatusColors}
    labels={interventionStatusLabels}
  />
);
