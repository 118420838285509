import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ShowButton,
  TextField,
} from "react-admin";
import { AddressField } from "../housing/fields/Address";

export const ResidenceList = () => {
  return (
    <List perPage={25} hasCreate={true}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="ESI" source="esi" />
        <ReferenceField
          label="Organization"
          source="organizationId"
          reference="organizations"
        >
          <TextField label="Name" source="name" />
        </ReferenceField>
        <AddressField label="Address" />
        <NumberField label="Housings" source="housingCount" />
        <DateField label="Created At" source="createdAt" />
        <DateField label="Updated At" source="updatedAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
