import PowerIcon from "@mui/icons-material/Bolt";
import { Grid, Box, Typography, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useState } from "react";
import {
  Datagrid,
  FunctionField,
  Pagination,
  ReferenceManyField,
  useRecordContext,
} from "react-admin";
import { RoundedNumberField } from "../../../../fields/RoundedNumberField";
import { useDemandResponseEntityDetails } from "../../../../providers/data-hooks/useDemandResponseEntityDetails";
import { MuiDateProvider } from "../../../electricity-prices/TempoCalendar";
import { ConsumptionProfileGraph } from "../../fields/ConsumptionProfile";
import type { DemandResponseEntity } from "../../type";

export const DemandResponseEntitySummaryTab = () => {
  const record = useRecordContext<DemandResponseEntity>();
  const { data } = useDemandResponseEntityDetails(record?.id);

  const [startDate, setStartDate] = useState<Date | null>(() =>
    dayjs().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(() => dayjs().toDate());

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FunctionField
          render={(record: any) =>
            record && (
              <Box display="flex" alignItems="center">
                <PowerIcon sx={{ marginRight: 1 }} />
                <Typography>
                  Maximum Capacity: {record.maxCapacityInMW}MW
                </Typography>
              </Box>
            )
          }
        />
        <FunctionField
          render={(record: any) =>
            record && (
              <Box display="flex" alignItems="center">
                <PowerIcon sx={{ marginRight: 1 }} />
                <Typography>
                  Heaters Power:{" ~"}
                  {data ? data.totalCapacityInMW.toFixed(3) : "..."}MW
                </Typography>
              </Box>
            )
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" mt={2}>
          Current pool
        </Typography>
        <ReferenceManyField
          reference="demand-response-pools"
          target="entity"
          filter={{
            startDate: { $lte: dayjs().format() },
            endDate: { $gte: dayjs().format() },
          }}
          sort={{ field: "startDate", order: "DESC" }}
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false}>
            <RoundedNumberField
              label="# Effacements"
              source="demandResponseStats.count"
            />
            <RoundedNumberField
              label="Prix de vente moyen (€)"
              source="demandResponseStats.avgPriceAmount"
            />
            <RoundedNumberField
              label="Total ventes (€)"
              source="demandResponseStats.totalPriceAmount"
            />
            <RoundedNumberField
              label="Profits totaux (€)"
              source="demandResponseStats.totalProfits"
            />
            <RoundedNumberField
              label="Puissance déclarée moyenne (MW)"
              source="demandResponseStats.avgPowerDeclared"
            />
            <RoundedNumberField
              label="Efficacité moyenne"
              source="demandResponseStats.avgEfficiency"
            />
            <RoundedNumberField
              label="Précision déclarée moyenne"
              source="demandResponseStats.avgAccuracyDeclared"
            />
            <RoundedNumberField
              label="Précision réalisée moyenne"
              source="demandResponseStats.avgAccuracyRealized"
            />
            <RoundedNumberField
              label="Émissions de CO2 évitées moyennes en kg (CO2 moyen)"
              source="demandResponseStats.avgAvoidedCO2WithAverageMethodInKg"
            />
            <RoundedNumberField
              label="Émissions de CO2 évitées moyennes en kg (CO2 marginal)"
              source="demandResponseStats.avgAvoidedCO2WithMarginalMethodInKg"
            />
          </Datagrid>
        </ReferenceManyField>
      </Grid>

      <Grid mt={2} sm={12} item>
        <MuiDateProvider>
          <DesktopDatePicker
            renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} />}
            value={startDate}
            onChange={setStartDate}
            label="Start Date"
            maxDate={dayjs().toDate()}
          />
          <DesktopDatePicker
            renderInput={(params) => <TextField {...params} />}
            value={endDate}
            onChange={setEndDate}
            label="End Date"
            minDate={startDate || undefined}
            maxDate={dayjs().toDate()}
          />
        </MuiDateProvider>
      </Grid>

      <Grid mt={2} sm={12} item>
        <ConsumptionProfileGraph startDate={startDate} endDate={endDate} />
      </Grid>
    </Grid>
  );
};
