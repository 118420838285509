import Close from "@mui/icons-material/Close";
import Menu from "@mui/icons-material/MenuOpen";
import { Box, IconButton, Paper } from "@mui/material";
import Highcharts from "highcharts";
import moreLoader from "highcharts/highcharts-more";
import annotationsLoader from "highcharts/modules/annotations";
import exportDataLoader from "highcharts/modules/export-data";
import exportingLoader from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";

annotationsLoader(Highcharts);
moreLoader(Highcharts);
exportingLoader(Highcharts);
exportDataLoader(Highcharts);

interface ChartProps {
  options: Highcharts.Options;
  isLoading?: boolean;
  description?: any;
}
export const Chart: React.FC<ChartProps> = ({
  options,
  isLoading,
  description,
}) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (isLoading === undefined || !chartRef.current) {
      return;
    }
    if (isLoading) {
      chartRef.current.chart.showLoading();
    } else {
      chartRef.current.chart.hideLoading();
    }
  }, [isLoading]);

  const [showDescription, setShowDescription] = useState(false);

  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      {description && (
        <>
          <IconButton
            onClick={() => setShowDescription((v) => !v)}
            className="no-print"
            sx={{
              position: "absolute",
              right: showDescription ? 10 : 0,
              bottom: 0,
              transition: "200ms",
              zIndex: 3,
            }}
          >
            {showDescription ? <Close /> : <Menu />}
          </IconButton>
          <Paper
            elevation={1}
            className="no-print"
            sx={{
              position: "absolute",
              right: showDescription ? 0 : -320,
              transition: "right 200ms",
              zIndex: 2,
              width: 320,
              height: "100%",
              paddingTop: 3,
              paddingLeft: 1,
              paddingRight: 1,
              paddingBottom: 1,
              overflow: "auto",
            }}
          >
            {description}
          </Paper>
        </>
      )}
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={{
          ...baseOptions,
          ...options,
        }}
      />
    </Box>
  );
};

const baseOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
};
