import { Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import type { TooltipProps } from "@mui/material";
import { blue, grey, orange } from "@mui/material/colors";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import { max, min, range } from "lodash";
import { useState } from "react";
import type { ReactNode } from "react";

const BLOCK_SIZE = 20;

interface Datum {
  color: string;
  tooltip?: ReactNode;
}
interface DeviceTimelineProps {
  data: Record<string, Datum>;
  onTimeRangeSelection: (dateRange: { start: string; end: string }) => void;
  selectedRange: { start: string; end: string };
}

export const DeviceTimeline: React.FC<DeviceTimelineProps> = ({
  data,
  selectedRange,
  onTimeRangeSelection,
}) => {
  const [hoverDay, setHoverDay] = useState<string>();
  const [selectedDay, setSelectedDay] = useState<string>();

  const firstDate = dayjs(
    min(Object.keys(data)) ?? dayjs().subtract(1, "years")
  )
    .locale({ ...en, weekStart: 1 })
    .startOf("week");

  const days = range(dayjs().add(1, "week").diff(firstDate, "days")).map((i) =>
    firstDate.add(i, "days")
  );

  const months = range(dayjs().endOf("month").diff(firstDate, "months")).map(
    (i) => firstDate.add(i + 1, "months").startOf("month")
  );

  const getColor = (datum: any | undefined, day: string) => {
    if (day === dayjs().format("YYYY-MM-DD")) {
      return orange[400];
    }
    if (!datum) {
      return grey[200];
    }

    if (datum.color) {
      return datum.color;
    }
  };

  const getSecondaryColor = (datum: any | undefined, day: string) => {
    let isSelected = false;
    if (day === selectedDay) {
      isSelected = true;
    }
    if (
      !selectedDay &&
      selectedRange.start <= day &&
      selectedRange.end >= day
    ) {
      isSelected = true;
    }
    if (selectedDay && hoverDay) {
      if (hoverDay >= selectedDay && selectedDay <= day && hoverDay >= day) {
        isSelected = true;
      }
      if (hoverDay <= selectedDay && selectedDay >= day && hoverDay <= day) {
        isSelected = true;
      }
    }

    if (isSelected) {
      return blue[300];
    }

    return;
  };

  const onDayClick = (day: string) => {
    if (selectedDay) {
      onTimeRangeSelection({
        start: min([selectedDay, day])!,
        end: max([selectedDay, day])!,
      });
      setSelectedDay(undefined);
    } else {
      setSelectedDay(day);
    }
  };

  return (
    <Wrapper>
      {months.map((startOfMonth) => (
        <Month
          key={startOfMonth.format("YYYY-MM")}
          style={{
            left: (startOfMonth.diff(firstDate, "days") / 7) * BLOCK_SIZE,
            top: 0,
          }}
        >
          {startOfMonth.format("MMM YY")}
        </Month>
      ))}
      {days.map((date) => {
        const day = date.format("YYYY-MM-DD");
        const datum = data[day];
        const secondaryColor = getSecondaryColor(datum, day);
        return (
          <DataTooltip
            key={day}
            title={
              datum?.tooltip ?? <Typography variant="caption">{day}</Typography>
            }
            followCursor
          >
            <Day
              style={{
                left: date.diff(firstDate, "weeks") * BLOCK_SIZE,
                top:
                  LABEL_SIZE +
                  date.locale({ ...en, weekStart: 1 }).weekday() * BLOCK_SIZE,
                backgroundColor: getColor(datum, day),
                borderColor: secondaryColor,
                borderWidth: secondaryColor ? 3 : 1,
              }}
              onMouseEnter={() => selectedDay && setHoverDay(day)}
              onClick={() => {
                setHoverDay(undefined);
                onDayClick(day);
              }}
            ></Day>
          </DataTooltip>
        );
      })}
    </Wrapper>
  );
};

const LABEL_SIZE = BLOCK_SIZE;

const Day = styled("div")`
  width: ${BLOCK_SIZE - 2}px;
  height: ${BLOCK_SIZE - 2}px;
  border: solid 1px black;
  margin: 2px;
  border-radius: 3px;
  background-color: #cacaca;
  position: absolute;
`;

const Month = styled("div")`
  height: ${LABEL_SIZE - 2}px;
  border-left: solid 1px black;
  margin-bottom: 2px;
  position: absolute;
  font-size: 12px;
  padding-left: 3px;
`;

const Wrapper = styled("div")`
  position: relative;
  height: ${7 * BLOCK_SIZE + LABEL_SIZE}px;
  overflow-x: auto;
  width: 100%;
`;

const DataTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
