import Icon from "@mui/icons-material/Engineering";
import { useRecordContext } from "react-admin";
import { useExport } from "../../../actions/useExport";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const ExportInterventions = () => {
  const record = useRecordContext();

  const { mutate } = useExport(() =>
    dataProvider.exportInterventions(record.id as string)
  );
  return (
    <ActionMenuItem
      label="Export Interventions"
      icon={<Icon />}
      onClick={mutate}
    />
  );
};
