import { Paper } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useQuery } from "react-query";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { useYearlyNormalized } from "../YearlyNormalizationSelect";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface ConsumptionGraphProps {
  filter: any;
}

export const MonthlySavingsGraph: React.FC<ConsumptionGraphProps> = ({
  filter,
}) => {
  const [yearlyNormalized] = useYearlyNormalized();

  const { data } = useQuery({
    queryFn: () =>
      dataProvider.getDevicesMonthlySavings(filter, yearlyNormalized),
    queryKey: [`devices/savings/monthly`, filter, yearlyNormalized],
    enabled: !!filter,
  });

  return (
    <Paper elevation={1}>
      <Chart
        isLoading={!data}
        options={{
          ...chartOptions,
          subtitle: {
            text:
              data &&
              `Based on v2 only - Last Update: ${dayjs().to(
                data.lastUpdateDate
              )}`,
          },
          xAxis: {
            categories: data?.data.map((d) => d.key),
            crosshair: true,
          },
          series: data && [
            {
              name: "€",
              type: "spline",
              color: "#2196f3",
              tooltip: {
                headerFormat: "<b>{point.key}</b>: ",
                valueDecimals: 0,
                pointFormat: `{point.y}€ ({point.custom.deviceCount} devices)`,
              },
              data: data.data.map(
                (point) =>
                  ({
                    y: point.savingsInEuros,
                    custom: {
                      deviceCount: point.deviceCount,
                    },
                  } as any)
              ),
            },
            {
              name: "kg CO2eq",
              type: "spline",
              color: "#f44336",
              yAxis: 1,
              tooltip: {
                headerFormat: "<b>{point.key}</b>: ",
                valueDecimals: 0,
                pointFormat: `{point.y}kg CO2 ({point.custom.deviceCount} devices)`,
              },
              data: data.data.map(
                (point) =>
                  ({
                    y: point.savingsInCO2,
                    custom: {
                      deviceCount: point.deviceCount,
                    },
                  } as any)
              ),
            },
          ],
        }}
      />
    </Paper>
  );
};

const chartOptions: Highcharts.Options = {
  title: {
    text: "Économies Générées",
  },
  plotOptions: {
    spline: {
      lineWidth: 3,
      states: {
        hover: {
          lineWidth: 5,
        },
      },
      marker: {
        enabled: false,
      },
    },
  },
  yAxis: [
    {
      min: 0,
      title: {
        text: "€",
      },
    },
    {
      min: 0,
      title: {
        text: "kg CO2",
      },
      opposite: true,
    },
  ],
};
