import { Backdrop, IconButton, Portal, Tooltip } from "@mui/material";
import { useState } from "react";
import { useRecordContext } from "react-admin";
import type { FieldProps } from "react-admin";
import Barcode from "react-barcode";
import type { Device } from "../types";
import BarcodeImg from "./barcode.png";

export default function BarcodeField(props: FieldProps<Device>) {
  const record = useRecordContext(props);

  const [backdropDisplay, setBackdropDisplay] = useState(false);

  return (
    <>
      <Tooltip title="Show barcode">
        <IconButton onClick={() => setBackdropDisplay(true)} size="small">
          <img src={BarcodeImg} alt="" width={20} />
        </IconButton>
      </Tooltip>

      <Portal>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropDisplay}
          onClick={() => setBackdropDisplay(false)}
        >
          <Barcode value={String(record.IMEI)} width={2} height={40} />
        </Backdrop>
      </Portal>
    </>
  );
}
