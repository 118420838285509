import { Divider, Paper, Typography } from "@mui/material";
import { sum } from "lodash";
import { useQuery } from "react-query";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";

interface ContractTypeProps {
  filter: any;
}

export const ContractType: React.FC<ContractTypeProps> = ({ filter }) => {
  const { data } = useQuery({
    queryFn: () => dataProvider.getDevicesContractType(filter),
    queryKey: [`devices/contract-type`, filter],
  });

  return (
    <Paper elevation={1}>
      <Chart
        isLoading={!data?.data}
        options={{
          title: {
            text: "Type de contrat",
          },
          subtitle: {
            text:
              data?.data &&
              `D'après ${sum(
                Object.values(data.data)
              )} boitiers v1 & v2 installés`,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                distance: -40,
                formatter: function () {
                  return this.percentage.toFixed(0) + "%";
                },
              },
              showInLegend: true,
              startAngle: -90,
              endAngle: 90,
              center: ["50%", "75%"],
              size: "110%",
            },
          },
          series: data && [
            {
              type: "pie",
              name: "Type de contrat",
              innerSize: "50%",
              tooltip: {
                headerFormat: "<b>{point.key}: ",
                pointFormat: `{point.y} chauffe-eaux</b>
          `,
              },
              data: [
                {
                  contractType: "HP/HC",
                  label: "heures pleines / heures creuses",
                  color: "#607D8B",
                },

                {
                  contractType: "BASE",
                  label: "Base",
                  color: "#9e9e9e",
                },
                {
                  contractType: "UNKNOWN",
                  label: "Inconnu",
                  color: "#ffc107",
                },
              ]
                .map((series) => ({
                  name: series.label,
                  color: series.color,
                  y: data.data[series.contractType] ?? 0,
                }))
                .filter((data) => data.y > 0),
            },
          ],
        }}
        description={
          <>
            <Divider sx={{ color: "#ffc107", marginTop: 1 }}>Inconnu</Divider>
            <Typography variant="body2">
              Le type de contrat est détectable après au moins une semaine
              d'observation. Un locataire qui branche/débranche son chauffe-eau
              selon ses besoins empêche de détecter le type de contrat.
            </Typography>

            <Divider sx={{ color: "#607D8B", marginTop: 2 }}>HP/HC</Divider>
            <Typography variant="body2">
              Utilisateur disposant d’un contrat heure creuse / heure pleine. Le
              chauffe-eau fonctionne par intermittence
            </Typography>

            <Divider sx={{ color: "#9e9e9e", marginTop: 2 }}>Base</Divider>
            <Typography variant="body2">
              Utilisateur disposant d’un contrat Base. Le chauffe-eau fonctionne
              en continu.
            </Typography>
          </>
        }
      />
    </Paper>
  );
};
