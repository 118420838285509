import { useQuery } from "react-query";
import type { UseQueryOptions } from "react-query";
import type { DailyStats } from "../../resources/devices/types";
import { axiosClient } from "../data";

export const useDeviceDailyStats = (
  deviceId?: string,
  options?: UseQueryOptions<DailyStats[]>
) => {
  const route = `/devices/${deviceId}/daily-stats`;
  return useQuery({
    queryKey: route,
    queryFn: () => axiosClient.get<DailyStats[]>(route).then((res) => res.data),
    enabled: !!deviceId,
    ...options,
  });
};
