import { useQuery } from "react-query";
import { axiosClient } from "../data";

export const useIncidentsOverTime = (filter: any) => {
  const { data } = useQuery(["incidents/over-time", filter], () =>
    axiosClient
      .get<Record<string, Record<string, number>>>("/incidents/over-time", {
        params: {
          filter: JSON.stringify(filter),
        },
      })
      .then((res) => res.data)
  );
  return data;
};
