import { Paper } from "@mui/material";
import type Highcharts from "highcharts";
import { clamp, groupBy, range } from "lodash";
import { useRedirect } from "react-admin";
import { useQuery } from "react-query";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { useYearlyNormalized } from "../YearlyNormalizationSelect";

interface AnnualSavingsProps {
  filter: any;
}

const minValue = 0;
const maxValue = 150;
const step = 10;

export const AnnualSavings: React.FC<AnnualSavingsProps> = ({ filter }) => {
  const redirect = useRedirect();
  const [yearlyNormalized] = useYearlyNormalized();

  const { data, isFetching } = useQuery({
    queryFn: () => dataProvider.getAnnualSavings(filter, yearlyNormalized),
    queryKey: [`devices/consumption`, filter, yearlyNormalized],
    enabled: !!filter,
    staleTime: 60 * 1000,
  });

  const targetCount = data?.data.filter(
    ({ annualSavingsInEuro }) => annualSavingsInEuro >= 38.5
  ).length;
  const totalCount = data?.data.length;

  const values = groupBy(data?.data || [], ({ annualSavingsInEuro }) => {
    const upperLimit = Math.ceil(annualSavingsInEuro / step) * step;
    return clamp(upperLimit, minValue, maxValue + step);
  });

  const categories = range(minValue, maxValue + step * 2, step);

  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Répartition des économies annuelles",
    },
    subtitle: {
      text:
        targetCount && totalCount
          ? `${((targetCount / totalCount) * 100).toFixed(0)}% > 38.5€/an`
          : "",
    },
    xAxis: {
      categories: categories.map(String),
      crosshair: true,
      labels: {
        formatter: (context) => {
          if ((context.value as number) <= minValue) {
            return `< ${context.value}`;
          }
          if ((context.value as number) > maxValue) {
            return `> ${context.value}`;
          }
          return `${Number(context.value) - step} -> ${Number(context.value)}`;
        },
      },
      plotBands: [
        {
          from: -1,
          to: (40 - minValue) / step,
          color: "#f4433655",
        },
        {
          from: (40 - minValue) / step,
          to: (maxValue - minValue) / step + 2,
          color: "#4caf5055",
        },
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Nombre de boîtier",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0.1,
      },
      series: {
        events: {
          click: (event) => {
            redirect(
              () =>
                `devices?filter=${JSON.stringify({
                  id: (event.point as any).custom.ids,
                })}`
            );
          },
        },
      },
    },
    series: [
      {
        name: "Count",
        type: "column",
        showInLegend: false,
        data: categories.map((category) => {
          const categoryData = values[category] ?? [];
          return {
            y: categoryData.length,
            custom: {
              ids: categoryData.map(({ id }) => id),
            },
          };
        }),
        color: "#607d8b",
      },
    ],
  };

  return (
    <Paper elevation={1}>
      <Chart options={options} isLoading={!data || isFetching} />
    </Paper>
  );
};
