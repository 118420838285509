import { Divider } from "@mui/material";
import { ConsumptionExport } from "../../../actions/ConsumptionExport";
import { ExportPDFAction } from "../../../actions/PDFReport";
import { ActionsMenu, EditAction } from "../../../layout/actions-menu";
import { AssignFirmware } from "../../devices/bulk-actions/AssignFirmware";
import { ChangePilotConfig } from "../../devices/bulk-actions/edit-device-config";
import { ExportInterventions } from "./Export";
import { SynchroniseListing } from "./SynchroniseListing";

export const ProjectShowActions = () => (
  <ActionsMenu>
    <EditAction />
    <AssignFirmware />
    <ChangePilotConfig />
    <Divider />
    <ConsumptionExport />
    <ExportInterventions />
    <ExportPDFAction />
    <Divider />
    <SynchroniseListing />
  </ActionsMenu>
);
