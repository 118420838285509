import DebugFrameIcon from "@mui/icons-material/Terminal";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import type { DebugFrame } from "../../../../../frames/debug/type";
import type { FrameSpecs } from "./shared";

export const debugFrameSpecs: FrameSpecs<DebugFrame> = {
  icon: <DebugFrameIcon sx={{ color: "#ff9800" }} />,
  data: [
    {
      key: "Logs" as const,
      label: (frame) => <Logs frame={frame} />,
    },
  ],
};

const Logs: React.FC<{ frame: any }> = ({ frame }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Read</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent sx={{ backgroundColor: "#ededed" }}>
          <pre>{frame.logs}</pre>
        </DialogContent>
      </Dialog>
    </>
  );
};
