import { Alert, Box, Tooltip } from "@mui/material";
import { useRecordContext } from "react-admin";
import type { FieldProps } from "react-admin";
import type { Device } from "../types";

export default function BypassedRemoteSwitchField(props: FieldProps<Device>) {
  const record = useRecordContext<Device>(props);

  if (!record || record.majorHWVersion === 2) return null;

  if (record.majorHWVersion === 1) {
    return (
      <Tooltip
        title={
          record.bypassedRemoteSwitch
            ? "This device uses a legacy electrical installation that should be removed if an intervention occures in the corresponding housing"
            : null
        }
      >
        <Alert severity="error" sx={{ marginTop: 1, marginBottom: 1 }}>
          Device V1
          {record.bypassedRemoteSwitch && " / Telerupteur CC"}
        </Alert>
      </Tooltip>
    );
  }

  if (record.majorHWVersion === 3) {
    return (
      <Box
        sx={{
          color: "#000",
          backgroundColor: "#00000052",
          borderRadius: 1,
          padding: 1,
          marginTop: 1,
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        BLACKBOX
      </Box>
    );
  }

  return null;
}
