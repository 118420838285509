import { FunctionField } from "react-admin";
import type { TextFieldProps } from "react-admin";
import type { Heater } from "../type";

export const getHeaterVolume = (heater: Heater) =>
  heater.volume ? `${heater.volume}L` : "Unknown";

export default function HeaterVolumeField(props: TextFieldProps<Heater>) {
  return (
    <FunctionField<Heater>
      render={getHeaterVolume}
      variant="body1"
      {...props}
    />
  );
}
