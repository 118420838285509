import { DialogActions, DialogContent, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useRecordContext } from "react-admin";
import { EditableListField } from "../../../fields/EditableListField";
import { ActionDialog, ActionDialogButton } from "../../../layout/actions-menu";
import { useUpdateWithNotification } from "../../../providers/data-hooks/useUpdateWithNotification";
import { IncidentActor, IncidentStatus } from "../types";
import type { Incident } from "../types";

export const SolveIncidentDialog = () => {
  const record = useRecordContext<Incident>();
  const [reason, setReason] = useState("");
  const [category, setCategory] = useState("");
  const [solve] = useUpdateWithNotification("incidents", record.id, {
    status: IncidentStatus.SOLVED,
    category,
    comment: reason,
    assignedTo: IncidentActor.OPS_TEAM,
  });

  return (
    <ActionDialog>
      <DialogContent>
        <Grid container spacing={1} minWidth={500}>
          <Grid item xs={12}>
            <EditableListField
              label="Category"
              value={category}
              onChange={(value) => setCategory(value)}
              listId={`Incident category for ${record.assignedTo}`}
              itemName={{
                singular: "category",
                plural: "categories",
                article: "a",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comment"
              helperText="Comment"
              value={reason}
              onChange={(e: any) => setReason(e.target.value)}
              multiline
              minRows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ActionDialogButton>Cancel</ActionDialogButton>
        <ActionDialogButton
          variant="contained"
          onClick={(_, onClose) => {
            solve();
            onClose();
          }}
        >
          Solve
        </ActionDialogButton>
      </DialogActions>
    </ActionDialog>
  );
};
