import { Tooltip } from "@mui/material";
import { useRecordContext } from "react-admin";
import { useDeploymentSummary } from "../../../providers/data-hooks/useDeploymentSummary";
import type { Deal } from "../../deal/type";
import {
  HousingStatus,
  housingStatusColors,
  housingStatusLabels,
} from "../../housing/types";
import type { Project } from "../type";

export const DeploymentProgress: React.FC<{
  entity: "deal" | "project";
  label?: string;
  width?: number;
  showCount?: boolean;
}> = ({ entity, width, showCount }) => {
  const record = useRecordContext<Deal | Project>();

  const { data } = useDeploymentSummary(entity, record.id);

  // search is does not support enriched fields for now
  if (!data) {
    return null;
  }

  return (
    <div
      style={{
        width: width ? `${width}px` : "100%",
        height: "28px",
        position: "relative",
        display: "flex",
        overflow: "hidden",
        borderRadius: 5,
      }}
    >
      {[
        HousingStatus.NOT_EQUIPPED,
        HousingStatus.NOT_EQUIPPABLE,
        HousingStatus.EQUIPPED,
        HousingStatus.TAKEN_OFF,
      ].map((status) => (
        <Tooltip
          key={status}
          title={`${data.housingCountByStatus[status]}/${data.housingCount} ${housingStatusLabels[status]}`}
        >
          <div
            style={{
              width: `${
                ((data.housingCountByStatus[status] ?? 0) /
                  (data.housingCount || 1)) *
                100
              }%`,
              backgroundColor: housingStatusColors[status] + "aa",
              color: housingStatusColors[status],
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {showCount === false
              ? null
              : data.housingCountByStatus[status] ?? 0}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
