import {
  Datagrid,
  DateField,
  FunctionField,
  ReferenceArrayField,
  TextField,
  useRecordContext,
} from "react-admin";

export const bucket = `https://storage.gra.cloud.ovh.net/v1/AUTH_091312b8bed64ca4b4238e53be6f0fdd/interventions`;

export const DocumentsField = () => {
  return (
    <ReferenceArrayField reference="documents" source="documentIds">
      <Datagrid expand={<PreviewPanel />} bulkActionButtons={false}>
        <TextField source="context" />
        <DateField source="createdAt" label="Date" />
        <FunctionField
          label="Link"
          render={(record: any) => (
            <a
              href={`${bucket}/${record.id}.${record.extension}`}
              target="_blank"
              rel="noreferrer"
            >
              Open
            </a>
          )}
        />
      </Datagrid>
    </ReferenceArrayField>
  );
};

const PreviewPanel = () => {
  const record = useRecordContext();

  if (["jpg", "jpeg", "png"].includes(record.extension)) {
    return (
      <img
        alt={record.context}
        src={`${bucket}/${record.id}.${record.extension}`}
        style={{ maxHeight: 400, maxWidth: 400 }}
      />
    );
  }

  return <div>No preview yet for extensions {record.extension}</div>;
};
