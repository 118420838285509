import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import dayjs from "dayjs";
import type { SeriesLineOptions } from "highcharts";
import { useMemo, useState } from "react";
import { Button, useNotify, useUpdate } from "react-admin";
import { useQuery } from "react-query";
import { TooltipWithDisabledSupport } from "../../../../../display/TooltipWithDisabledSupport";
import { dataProvider } from "../../../../../providers/data";
import { useDeviceConfigPersonas } from "../../../../../providers/data-hooks/useDeviceConfigPersonas";
import { useDeviceEndUser } from "../../../../../providers/data-hooks/useDeviceEndUser";
import { Mode } from "../../../../device-config/types";
import { DeviceConfigPersonaIcon } from "../../../../device-config-persona";
import { serializeEndUserName } from "../../../../end-users/fields/NameField";
import { SeriesName } from "../../../types";
import type { Device } from "../../../types";
import { TemperatureChart } from "../data/charts/Temperature";
import { getScheduleSeries } from "../data/charts/schedule-series";

export const SelectPersona: React.FC<{ device: Device }> = ({ device }) => {
  const { endUser, isLoading, refetch } = useDeviceEndUser(device);
  const personas = useDeviceConfigPersonas();
  const [selectedPersonaId, setSelectedPersonaId] = useState();
  const [update] = useUpdate();
  const notify = useNotify();
  const disabled = !isLoading && !endUser;
  const [open, setOpen] = useState(false);

  if (isLoading) {
    return <LinearProgress sx={{ width: 180, margin: 3 }} />;
  }

  const endUserPersona = personas?.find(
    (p) => p.id === endUser?.deviceConfigPersona
  );
  const selectedPersona =
    selectedPersonaId && personas?.find((p) => p.id === selectedPersonaId);

  return (
    <>
      <TooltipWithDisabledSupport
        title={
          endUser
            ? undefined
            : "Cannot assign a persona because no end user is related to this device"
        }
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpen(true)}
          label={endUserPersona?.name ?? "Assign a persona"}
          startIcon={<DeviceConfigPersonaIcon />}
          sx={{ margin: 1 }}
          disabled={!endUser}
        />
      </TooltipWithDisabledSupport>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Assign a persona</InputLabel>
            <Select
              disabled={disabled}
              value={selectedPersonaId ?? endUser?.deviceConfigPersona ?? ""}
              displayEmpty
              onChange={(event) => {
                setSelectedPersonaId(event.target.value as any);
              }}
              size="small"
              fullWidth
              sx={{ margin: 1 }}
            >
              <MenuItem value="" sx={{ height: 30 }}></MenuItem>
              {(personas ?? []).map((persona) => (
                <MenuItem key={persona.id} value={persona.id}>
                  {persona.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(selectedPersona || endUserPersona) && (
            <Preview
              device={device}
              persona={selectedPersona || endUserPersona}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            label="Save"
            disabled={selectedPersonaId === undefined}
            onClick={() => {
              update(
                "end-users",
                {
                  id: endUser?.id,
                  data: { deviceConfigPersona: selectedPersonaId || null },
                  previousData: endUser,
                },
                {
                  onSuccess: () => {
                    notify(
                      `Persona assigned to end user "${serializeEndUserName(
                        endUser!
                      )}"`
                    );
                  },
                  onError: (error: any) => {
                    notify(`An error occured: ${error.message}`);
                  },
                  onSettled: () => refetch(),
                }
              );
              setOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const Preview: React.FC<{ device: Device; persona: any }> = ({
  device,
  persona,
}) => {
  const isHPHC = device.HoraireHCHP ? device.HoraireHCHP.length > 0 : false;

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["smart-schedule", device.id, persona],
    queryFn: () => {
      return dataProvider.getSmartSchedule({
        deviceId: device.id,
        mode: Mode.SMART,
        parameters: isHPHC ? persona.parameters_hp_hc : persona.parameters_base,
      });
    },
    staleTime: 1000 * 60 * 2,
  });

  const additionalSeries = useMemo(() => {
    if (!data) {
      return [];
    }

    const series: SeriesLineOptions = {
      name: "Computed Schedule",
      data: getScheduleSeries(
        data.weeklyInstructions,
        dayjs().subtract(2, "days").toDate(),
        new Date()
      ),
      step: "left",
      type: "line",
      id: "schedule",
      gapSize: 0,
      color: "#8bc34a",
      selected: true,
    };

    return [series];
  }, [data]);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <TemperatureChart
        title="Preview"
        startDate={dayjs().subtract(2, "days").toDate()}
        endDate={new Date()}
        displayedSeries={[SeriesName.T1_AVG, SeriesName.SCHEDULE]}
        paramsOptions={{}}
        device={device}
        isFetching={isLoading || isFetching || !persona}
        additionalSeries={additionalSeries}
      />
    </div>
  );
};
