import type { ReactNode } from "react";
import { DateField, ReferenceOneField } from "react-admin";
import type { Housing } from "../types";

export interface EquipedFieldProps {
  label?: string;
}

export function EquipedField({
  label = "Equiped date",
}: EquipedFieldProps): ReactNode {
  return (
    <ReferenceOneField
      reference="interventions"
      target="housingId"
      label={label}
      sort={{ field: "datetime", order: "ASC" }}
    >
      <DateField source="datetime" />
    </ReferenceOneField>
  );
}

export const formatAddress = (record: Housing) =>
  `${record.additionalAddress ? `${record.additionalAddress} - ` : ""} ${
    record.address
  } ${record.zipCode} ${record.city}`;
