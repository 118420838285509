import { useRecordContext } from "react-admin";
import ReactMarkdown from "react-markdown";

interface MarkdownPanelProps {
  source: string;
}

export const MarkdownPanel: React.FC<MarkdownPanelProps> = ({ source }) => {
  const record = useRecordContext();

  return <ReactMarkdown>{record[source]}</ReactMarkdown>;
};
