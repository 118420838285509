import { Grid } from "@mui/material";
import { BooleanInput } from "react-admin";
import { NoWheelNumberInput } from "../../../fields/NumberInput";
import { integer } from "../../../validations/integer";

export const SmartEnergyParameters: React.FC<{
  source: string;
  disabled?: boolean;
}> = ({ source, disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={1}
          max={99}
          label="History Prediction Weeks"
          defaultValue={defaultValues.history_prediction_weeks}
          source={`${source}.history_prediction_weeks`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Temperature Legio [1°C]"
          defaultValue={defaultValues.temp_legio}
          source={`${source}.temp_legio`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Temperature Base [1°C]"
          defaultValue={defaultValues.temp_base}
          source={`${source}.temp_base`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Hysteresis Base [0.1°C]"
          defaultValue={defaultValues.hysteresis_hc_base_temp}
          source={`${source}.hysteresis_hc_base_temp`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Minimum Temperature Boost [1°C]"
          defaultValue={defaultValues.HC_min_preheat}
          source={`${source}.HC_min_preheat`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Hysteresis Boost [0.1°C]"
          defaultValue={defaultValues.hysteresis_hc_preheat}
          source={`${source}.hysteresis_hc_preheat`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <BooleanInput
          label="Soft Decrease Temperature"
          defaultValue={defaultValues.soft_decrease_temperature}
          source={`${source}.soft_decrease_temperature`}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <NoWheelNumberInput
          min={1}
          max={100}
          label="Soft Decrease Temperature [1°C]"
          defaultValue={defaultValues.soft_decrease_temp}
          source={`${source}.soft_decrease_temp`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <NoWheelNumberInput
          min={1}
          max={100}
          label="Soft Decrease Temperature Days"
          defaultValue={defaultValues.soft_decrease_days}
          source={`${source}.soft_decrease_days`}
          fullWidth
          validate={integer}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export const SmartEnergyParamsToString = ({
  HC_min_preheat,
  temp_base,
  ...other
}: any) =>
  `${temp_base ?? defaultValues.temp_base}°C/${
    HC_min_preheat ?? defaultValues.HC_min_preheat
  }°C ${Object.entries(other)
    .filter(
      ([key, value]) =>
        defaultValues[key as keyof typeof defaultValues] !== value
    )
    .map(([key, value]) => `${key}=${value}`)
    .join(", ")}`;

const defaultValues = {
  temp_base: 41,
  temp_legio: 62,
  history_prediction_weeks: 4,
  HC_min_preheat: 47,
  hysteresis_hc_base_temp: 10,
  hysteresis_hc_preheat: 10,
  soft_decrease_temperature: true,
  soft_decrease_temp: 10,
  soft_decrease_days: 10,
};
