import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";
import { useNotify, useRecordContext } from "react-admin";

interface CopyRecordFieldValueToClipboardProps<T> {
  title?: string;
  fieldName?: string;
  formatValue?: (value: T) => string;
}

export function CopyRecordFieldValueToClipboard<T>({
  title = "Copy to clipboard",
  fieldName,
  formatValue = String,
}: CopyRecordFieldValueToClipboardProps<T>) {
  const notify = useNotify();
  const record = useRecordContext();
  if (!record) return null;
  if (fieldName && !record[fieldName]) return null;

  return (
    <Tooltip title={title}>
      <IconButton
        color="primary"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          try {
            navigator.clipboard.writeText(
              formatValue(fieldName ? record[fieldName] : record)
            );
          } catch {
            notify("Error copying");
          }
        }}
      >
        <ContentCopyIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
}
