import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ShowButton,
  TextField,
} from "react-admin";

export const PostList = () => {
  return (
    <List perPage={100}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="title" label="Title" />
        <DateField source="publishedAt" label="Published Date" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
