import { useGetList } from "react-admin";
import type { Device } from "../../resources/devices/types";
import type { EndUser } from "../../resources/end-users/type";

export const useDeviceEndUser = (device: Device | undefined) => {
  const { data: endUsers, ...result } = useGetList<EndUser>(
    "end-users",
    {
      filter: {
        device: device?.id,
      },
    },
    { enabled: !!device }
  );

  return { endUser: endUsers && endUsers[0], ...result };
};
