import { useGetList } from "react-admin";
import type { UseQueryOptions } from "react-query";

export const useGetCount = (
  resource: string,
  filter: any,
  queryOptions?: UseQueryOptions
) => {
  const { total } = useGetList(
    resource,
    {
      filter,
      pagination: { page: 1, perPage: 2 }, // perPage=1 generate a miscount
    },
    queryOptions as any
  );

  return total;
};
