export const RelayState: React.FC<{ value: boolean }> = ({ value }) => {
  if (value) {
    return <Close />;
  }

  return <Open />;
};

const Open = () => (
  <svg
    version="1.1"
    viewBox="0.0 0.0 462.99212598425197 316.1496062992126"
    fill="none"
    stroke="none"
    strokeLinecap="square"
    strokeMiterlimit="10"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="p.0">
      <path
        d="m0 0l462.99213 0l0 316.1496l-462.99213 0l0 -316.1496z"
        clipRule="nonzero"
      />
    </clipPath>
    <g clipPath="url(#p.0)">
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m0 0l462.99213 0l0 316.1496l-462.99213 0z"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m98.07087 159.52231l0 0c0 -18.855957 15.285774 -34.14173 34.14173 -34.14173l0 0c9.054947 0 17.739029 3.5970688 24.141846 9.999886c6.402817 6.402817 9.999878 15.086899 9.999878 24.141846l0 0c0 18.855957 -15.285767 34.14174 -34.141724 34.14174l0 0c-18.855957 0 -34.14173 -15.285782 -34.14173 -34.14174z"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m98.07087 159.52231l0 0c0 -18.855957 15.285774 -34.14173 34.14173 -34.14173l0 0c9.054947 0 17.739029 3.5970688 24.141846 9.999886c6.402817 6.402817 9.999878 15.086899 9.999878 24.141846l0 0c0 18.855957 -15.285767 34.14174 -34.141724 34.14174l0 0c-18.855957 0 -34.14173 -15.285782 -34.14173 -34.14174z"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m290.34647 159.52231l0 0c0 -18.855957 15.285767 -34.14173 34.141724 -34.14173l0 0c9.054962 0 17.739044 3.5970688 24.141846 9.999886c6.402832 6.402817 9.999878 15.086899 9.999878 24.141846l0 0c0 18.855957 -15.285767 34.14174 -34.141724 34.14174l0 0c-18.855957 0 -34.141724 -15.285782 -34.141724 -34.14174z"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m290.34647 159.52231l0 0c0 -18.855957 15.285767 -34.14173 34.141724 -34.14173l0 0c9.054962 0 17.739044 3.5970688 24.141846 9.999886c6.402832 6.402817 9.999878 15.086899 9.999878 24.141846l0 0c0 18.855957 -15.285767 34.14174 -34.141724 34.14174l0 0c-18.855957 0 -34.141724 -15.285782 -34.141724 -34.14174z"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m98.07087 159.52231l-95.59055 0"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m98.07087 159.52231l-95.59055 0"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m358.6299 159.52231l101.921265 0"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m358.6299 159.52231l101.921265 0"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m156.35445 135.38046l133.76378 -77.22835"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m156.35445 135.38046l133.76378 -77.22835"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

const Close = () => (
  <svg
    version="1.1"
    viewBox="0.0 0.0 462.99212598425197 316.1496062992126"
    fill="none"
    stroke="none"
    strokeLinecap="square"
    strokeMiterlimit="10"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="p.0">
      <path
        d="m0 0l462.99213 0l0 316.1496l-462.99213 0l0 -316.1496z"
        clipRule="nonzero"
      />
    </clipPath>
    <g clipPath="url(#p.0)">
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m0 0l462.99213 0l0 316.1496l-462.99213 0z"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m98.07087 159.52231l0 0c0 -18.855957 15.285774 -34.14173 34.14173 -34.14173l0 0c9.054947 0 17.739029 3.5970688 24.141846 9.999886c6.402817 6.402817 9.999878 15.086899 9.999878 24.141846l0 0c0 18.855957 -15.285767 34.14174 -34.141724 34.14174l0 0c-18.855957 0 -34.14173 -15.285782 -34.14173 -34.14174z"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m98.07087 159.52231l0 0c0 -18.855957 15.285774 -34.14173 34.14173 -34.14173l0 0c9.054947 0 17.739029 3.5970688 24.141846 9.999886c6.402817 6.402817 9.999878 15.086899 9.999878 24.141846l0 0c0 18.855957 -15.285767 34.14174 -34.141724 34.14174l0 0c-18.855957 0 -34.14173 -15.285782 -34.14173 -34.14174z"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m290.34647 159.52231l0 0c0 -18.855957 15.285767 -34.14173 34.141724 -34.14173l0 0c9.054962 0 17.739044 3.5970688 24.141846 9.999886c6.402832 6.402817 9.999878 15.086899 9.999878 24.141846l0 0c0 18.855957 -15.285767 34.14174 -34.141724 34.14174l0 0c-18.855957 0 -34.141724 -15.285782 -34.141724 -34.14174z"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m290.34647 159.52231l0 0c0 -18.855957 15.285767 -34.14173 34.141724 -34.14173l0 0c9.054962 0 17.739044 3.5970688 24.141846 9.999886c6.402832 6.402817 9.999878 15.086899 9.999878 24.141846l0 0c0 18.855957 -15.285767 34.14174 -34.141724 34.14174l0 0c-18.855957 0 -34.141724 -15.285782 -34.141724 -34.14174z"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m98.07087 159.52231l-95.59055 0"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m98.07087 159.52231l-95.59055 0"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m358.6299 159.52231l101.921265 0"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m358.6299 159.52231l101.921265 0"
        fillRule="evenodd"
      />
      <path
        fill="#000000"
        fillOpacity="0.0"
        d="m166.35432 159.52231l124.000015 0"
        fillRule="evenodd"
      />
      <path
        stroke="#000000"
        strokeWidth="8.0"
        strokeLinejoin="round"
        strokeLinecap="butt"
        d="m166.35432 159.52231l124.000015 0"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
