import { Tab } from "@mui/material";
import type { TabProps } from "@mui/material";
import type { Except } from "type-fest";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import { useGetCount } from "../../../providers/data-hooks/useGetCount";
import type { IncidentStatus } from "../types";

type TabWithCountProps = Except<TabProps, "label"> & {
  status: IncidentStatus;
};

export const IncidentStatusTab: React.FC<TabWithCountProps> = ({
  status,
  ...props
}) => {
  const [filter] = useQueryParam("filter", withDefault(JsonParam, {}));
  const count = useGetCount("incidents", { ...filter, status });

  return (
    <Tab value={status} label={`${status} (${count ?? "..."})`} {...props} />
  );
};
