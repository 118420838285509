import { ReferenceField, TextField, useRecordContext } from "react-admin";
import { InterventionType } from "../../intervention/types";

export const InterventionDevice: React.FC<{ label?: string }> = () => {
  const intervention = useRecordContext();

  if (!intervention) {
    return null;
  }

  if (intervention.type === InterventionType.REPLACEMENT) {
    return (
      <>
        <ReferenceField
          label="Device"
          source="replacedDeviceId"
          reference="devices"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        {" → "}
        <ReferenceField
          label="Device"
          source="deviceId"
          reference="devices"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </>
    );
  }

  return (
    <ReferenceField
      label="Device"
      source="deviceId"
      reference="devices"
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
  );
};
