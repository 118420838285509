import { CountEnumChart } from "../../../layout/dashboard/widgets/CountEnumChart";
import {
  InterventionSourcingType,
  interventionSourcingTypeLabels,
} from "../types";
import { useInstallationPieFilter } from "./useInstallationPieFilter";

export const SourcingTypeStats = ({
  entity,
}: {
  entity: "deal" | "project";
}) => {
  const { counts, filteredValue, toggleFilter } = useInstallationPieFilter(
    "sourcingType",
    entity
  );

  return (
    <CountEnumChart
      title="Type de RDV"
      onClick={(point: any) => toggleFilter(point.custom.status)}
      data={
        counts &&
        [
          InterventionSourcingType.ACTIVE_CALL,
          InterventionSourcingType.PASSIVE_CALL,
          InterventionSourcingType.AUTONOMOUS,
          InterventionSourcingType.EMAIL,
          InterventionSourcingType.BUILDING_SIGN,
          InterventionSourcingType.LETTER,
          InterventionSourcingType.DOOR_TO_DOOR,
        ].map((status) => ({
          name: interventionSourcingTypeLabels[status],
          custom: {
            status,
          },
          y: counts[status],
          sliced: filteredValue === status,
        }))
      }
    />
  );
};
