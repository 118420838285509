import { Grid } from "@mui/material";
import {
  DateField,
  Edit,
  ReferenceField,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
} from "react-admin";
import { InlineLabel } from "../../layout/InlineLabel";
import { MuteButton } from "./actions/Mute";
import { AlertCodeField } from "./fields/AlertCodeField";
import { AlertStatusField } from "./fields/AlertStatusField";

const EditionForm = () => {
  return (
    <SimpleForm toolbar={<AlertEditToolbar />}>
      <Grid container spacing={2}>
        <Grid item xs={12} justifyContent="center" display="flex">
          <AlertCodeField />
        </Grid>
        <Grid item xs={12} justifyContent="center" display="flex">
          <AlertStatusField />
        </Grid>
        <Grid item xs={12} justifyContent="center" display="flex">
          <InlineLabel label="Device">
            <ReferenceField reference="devices" source="deviceId" link="show">
              <TextField source="name" />
            </ReferenceField>
          </InlineLabel>
        </Grid>
        <Grid item xs={12} justifyContent="center" display="flex">
          <InlineLabel label="Since">
            <DateField source="timeStart" />
          </InlineLabel>
        </Grid>
        <Grid item xs={12}>
          <TextInput source="comment" fullWidth multiline minRows={3} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const AlertEdit = () => (
  <Edit
    actions={<AlertEditActions />}
    redirect={false}
    mutationMode="optimistic"
  >
    <EditionForm />
  </Edit>
);

export const AlertEditActions = () => (
  <TopToolbar>
    <MuteButton />
  </TopToolbar>
);

const AlertEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
