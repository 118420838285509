import Icon from "@mui/icons-material/DriveFolderUpload";
import {
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { useMutation } from "react-query";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";
import type { Organization } from "../../types";

export const AddSftpUser = () => {
  const record = useRecordContext<Organization>();
  const [username, setUsername] = useState("");
  const notify = useNotify();
  const refresh = useRefresh();

  const {
    mutate: addSftpUser,
    isLoading,
    data,
  } = useMutation(
    ["/sftp-user"],
    async () => {
      const password = await dataProvider.addSftpUser(record.id, username);
      refresh();

      return password;
    },
    {
      onError: (error: any) => {
        notify(`Failed to add sftp user: ${error.message}`, {
          type: "error",
        });
      },
    }
  );

  return (
    <DialogActionMenuItem
      label="Add SFTP user"
      icon={<Icon />}
      dialog={
        <ActionDialog fullWidth>
          <DialogTitle>Add Sftp User</DialogTitle>
          <DialogContent>
            {data ? (
              <Alert severity="success">
                SFTP user created. Temporary password is{" "}
                <code
                  style={{
                    backgroundColor: "#9e9e9e54",
                    padding: "2px 5px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {data}
                </code>
                . User must set password at
                https://web.sftp.elaxenergy.com/web/client/login
              </Alert>
            ) : (
              <TextField
                multiline
                margin="normal"
                label="Username"
                fullWidth
                value={username}
                autoFocus
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            {data ? (
              <ActionDialogButton
                variant="contained"
                onClick={(_, onClose) => onClose()}
              >
                Close
              </ActionDialogButton>
            ) : (
              <>
                <ActionDialogButton>Cancel</ActionDialogButton>
                <ActionDialogButton
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => addSftpUser()}
                >
                  Add user
                </ActionDialogButton>
              </>
            )}
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
