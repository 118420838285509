import {
  Button,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";

export const FalsePositiveButton: React.FC<{ label?: string }> = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();

  const [update, { isLoading }] = useUpdate(
    "alerts",
    {
      id: record.id,
      data: { isFalsePositive: true },
    },
    {
      onSuccess: () => {
        notify("Thanks for the feedback!");
        refresh();
      },
      onError: (error: any) => {
        notify(`Failed: ${error.message}`, {
          type: "error",
        });
      },
    }
  );

  return (
    <Button
      onClick={() => update()}
      label="False Positive"
      disabled={isLoading}
    />
  );
};
