import { useWatch, useFormContext } from "react-hook-form";
import { EditableListField } from "../../../../fields/EditableListField";
import { IncidentActor } from "../../types";

interface ActionInputProps {
  source: string;
}

export const ActionInput: React.FC<ActionInputProps> = ({ source }) => {
  const assignedTo = useWatch({ name: "assignedTo" });
  const currentValue = useWatch({ name: source });
  const { setValue } = useFormContext();

  if (!assignedTo) {
    return null;
  }

  const label =
    assignedTo === IncidentActor.MAINTENANCE_PROVIDER
      ? "Mission Order"
      : "Action";

  return (
    <EditableListField
      label={label}
      value={currentValue}
      onChange={(value) => setValue(source, value)}
      itemName={{
        singular: "action",
        plural: "actions",
        article: "an",
      }}
      listId={`Incident actions for ${assignedTo}`}
      sx={{ marginBottom: 1 }}
    />
  );
};
