import CircleIcon from "@mui/icons-material/Circle";
import { Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import { DateField, Link, TextField } from "react-admin";
import FunctionList from "../../../../../display/FunctionList";
import { interventionStatusColors } from "../../../../intervention/types";
import type { Intervention } from "../../../../intervention/types";
import type { Device } from "../../../types";
import { BlackboxDetails } from "./BlackboxDetails";

interface InterventionSummaryProps {
  device: Device;
}

export const InterventionSummary: React.FC<InterventionSummaryProps> = ({
  device,
}) => {
  return (
    <FunctionList<Intervention>
      render={(intervention) => (
        <>
          <Tooltip
            title={
              <>
                <Typography variant="body2">
                  Status: {intervention.status}
                </Typography>
                <DateField record={intervention} source="datetime" showTime />
                <Typography variant="body2">
                  Technician: {intervention.technician ?? ""}
                </Typography>
              </>
            }
            arrow
          >
            <Stack direction="row" spacing={1} marginTop={1}>
              <Box paddingTop="2px">
                <CircleIcon
                  sx={{
                    color: intervention.status
                      ? interventionStatusColors[intervention.status]
                      : colors.grey[500],
                  }}
                  fontSize="small"
                />
              </Box>
              <Link to={`/interventions/${intervention.id}/show`}>
                <TextField
                  record={intervention}
                  source="type"
                  variant="body1"
                />{" "}
                <DateField
                  record={intervention}
                  source="datetime"
                  variant="body1"
                />
              </Link>
            </Stack>
          </Tooltip>
          {device?.majorHWVersion === 3 && (
            <BlackboxDetails intervention={intervention} />
          )}
        </>
      )}
    />
  );
};
