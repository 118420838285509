import dayjs from "dayjs";
import { useCallback } from "react";
import { DateTimeParam, useQueryParam, withDefault } from "use-query-params";
import type { UrlUpdateType } from "use-query-params";

export const useDateRangeFilter = () => {
  const [startDate, setStartDate] = useQueryParam(
    "startDate",
    withDefault(
      DateTimeParam,
      dayjs().subtract(7, "days").startOf("day").toDate()
    )
  );

  const [endDate, setEndDate] = useQueryParam(
    "endDate",
    withDefault(DateTimeParam, dayjs().endOf("day").toDate())
  );

  const zoomOut = useCallback(() => {
    const maxEndDate = dayjs().endOf("day");
    const delta = dayjs(endDate).diff(startDate);
    const zoomOutMs = delta / 2;
    const rightZoomOut = Math.min(maxEndDate.diff(endDate), zoomOutMs / 2);
    const leftZoomOut = zoomOutMs - rightZoomOut;
    setEndDate(dayjs(endDate).add(rightZoomOut, "milliseconds").toDate());
    setStartDate(
      dayjs(startDate).subtract(leftZoomOut, "milliseconds").toDate()
    );
  }, [startDate, endDate, setStartDate, setEndDate]);

  const previous = useCallback(() => {
    const delta = dayjs(endDate).diff(startDate);
    const shift = Math.floor((delta * 3) / 4);
    setStartDate(dayjs(startDate).subtract(shift, "milliseconds").toDate());
    setEndDate(dayjs(endDate).subtract(shift, "milliseconds").toDate());
  }, [setStartDate, setEndDate, endDate, startDate]);

  const next = useCallback(() => {
    const delta = dayjs(endDate).diff(startDate);
    const maxEndDate = dayjs().endOf("day");
    const shift = Math.min(
      Math.floor((delta * 3) / 4),
      dayjs(maxEndDate).diff(endDate)
    );
    setStartDate(dayjs(startDate).add(shift, "milliseconds").toDate());
    setEndDate(dayjs(endDate).add(shift, "milliseconds").toDate());
  }, [setStartDate, setEndDate, endDate, startDate]);

  return {
    dateRange: { startDate, endDate },
    setDateRange: (
      range: {
        startDate: Date | undefined;
        endDate: Date | undefined;
      },
      updateType: UrlUpdateType = "pushIn"
    ) => {
      setStartDate(range.startDate, updateType);
      setEndDate(range.endDate, updateType);
    },
    zoomOut,
    previous,
    next,
    isMax: dayjs().endOf("day").isSame(endDate),
  };
};
