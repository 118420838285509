import { useCanAccess } from "@react-admin/ra-rbac";
import { useRecordContext, useGetList } from "react-admin";

export const useActivityLogs = () => {
  const record = useRecordContext();

  const { canAccess } = useCanAccess({
    action: "read",
    resource: "activity-logs",
  });
  const { data, total } = useGetList(
    "activity-logs",
    {
      filter: { recordId: record?.id },
      sort: { field: "createdAt", order: "DESC" },
    },
    { enabled: !!record && canAccess }
  );

  return { record, canAccess, data, total };
};
