import type { ReactNode } from "react";
import { SelectInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import {
  InterventionType,
  InterventionRemovalResponsible,
  interventionRemovalResponsibleLabels,
} from "../types";

/**
 * Reset `removalResponsible` field when the `interventionType` is not `InterventionType.REMOVAL`
 * as the field does not have sens with other intervention types.
 */
export const useResetRemovalResponsibleField = (
  interventionType: InterventionType
) => {
  const { setValue } = useFormContext();

  if (interventionType !== InterventionType.REMOVAL) {
    setValue("removalResponsible", undefined);
  }
};

export const removalResponsibleChoices = Object.values(
  InterventionRemovalResponsible
).map((value) => ({
  id: value,
  name: interventionRemovalResponsibleLabels[value],
}));

export function RemovalResponsibleInput(): ReactNode {
  return (
    <SelectInput
      source="removalResponsible"
      label="Removal responsible"
      choices={removalResponsibleChoices}
      fullWidth
      emptyText="No responsible"
    />
  );
}
