import {
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
} from "react-admin";
import { InterventionType } from "../../intervention/types";
import ProjectNameField from "../../project/fields/ProjectNameField";

export const DeviceProjectsField = ({ label }: { label?: string }) => {
  return (
    <ReferenceManyField
      label={label || "Projects"}
      reference="interventions"
      target="deviceId"
      filter={{
        type: [InterventionType.INSTALLATION, InterventionType.REPLACEMENT],
      }}
      sort={{ field: "datetime", order: "DESC" }}
      pagination={<Pagination />}
    >
      <SingleFieldList>
        <ReferenceField reference="projects" source="projectId" link="show">
          <ProjectNameField variant="body1" />
        </ReferenceField>
      </SingleFieldList>
    </ReferenceManyField>
  );
};
