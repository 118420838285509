import { Paper } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useQuery } from "react-query";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { useYearlyNormalized } from "../YearlyNormalizationSelect";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface ConsumptionGraphProps {
  filter: any;
}

export const ConsumptionProgressionGraph: React.FC<ConsumptionGraphProps> = ({
  filter,
}) => {
  const [yearlyNormalized] = useYearlyNormalized();

  const { data } = useQuery({
    queryFn: () =>
      dataProvider.getDevicesSavingProgression(filter, yearlyNormalized),
    queryKey: [`devices/savings/progression`, filter, yearlyNormalized],
    enabled: !!filter,
  });

  return (
    <Paper elevation={1}>
      <Chart
        isLoading={!data}
        options={{
          title: {
            text: "Évolution des économies",
          },
          subtitle: {
            text: data && `Last Update: ${dayjs().to(data.lastUpdateDate)}`,
          },
          plotOptions: {
            line: {
              lineWidth: 2,
              states: {
                hover: {
                  lineWidth: 3,
                },
              },
              marker: {
                enabled: false,
              },
            },
          },
          xAxis: {
            crosshair: true,
            type: "datetime",
            tickPixelInterval: 150,
          },
          yAxis: [
            {
              title: {
                text: "Wh corrigé (saison & absence)",
              },
            },
          ],
          series: data && [
            {
              name: "Average consumption during observation (Wh)",
              type: "line",
              color: "#2196f3",
              tooltip: {
                headerFormat: "<b>{point.key}</b>: ",
                valueDecimals: 0,
              },
              data: data.data.map(
                (point) =>
                  ({
                    x: point.timestamp,
                    y: point.averageReferenceConsumptionInWh,
                  } as any)
              ),
            },
            {
              name: "Average consumption during pilot (Wh)",
              type: "line",
              color: "#4caf50",
              tooltip: {
                headerFormat: "<b>{point.key}</b>: ",
                valueDecimals: 0,
              },
              data: data.data.map(
                (point) =>
                  ({
                    x: point.timestamp,
                    y: point.averagePilotConsumptionInWh,
                  } as any)
              ),
            },
            {
              name: "Économies",
              type: "arearange",
              data: data.data.map((point) => [
                point.timestamp,
                point.averagePilotConsumptionInWh,
                point.averageReferenceConsumptionInWh,
              ]),
              marker: {
                enabled: false,
              },
              showInLegend: false,
              lineWidth: 0,
              color: "#607d8b",
              negativeColor: "#f44336",
              fillOpacity: 0.3,
              enableMouseTracking: false,
            },
          ],
        }}
      />
    </Paper>
  );
};
