import { Alert } from "@mui/material";
import { EditButton, RecordContext, useListContext } from "react-admin";
import { alertSeverities } from "../../alerts";
import { codeToLabel } from "../../alerts/fields/AlertCodeField";

export const Alerts = () => {
  const { data } = useListContext();

  if (!data) {
    return null;
  }

  return (
    <>
      {data.map((alert) => (
        <RecordContext.Provider value={alert} key={alert.id}>
          <Alert
            severity={alertSeverities[alert.code] || "warning"}
            sx={{ marginTop: 1, marginBottom: 1 }}
            action={<EditButton color="inherit" size="small" label="Comment" />}
          >
            {alert.message || codeToLabel[alert.code]?.label || alert.code}
            {alert.comment ? ` (${alert.comment})` : ""}
          </Alert>
        </RecordContext.Provider>
      ))}
    </>
  );
};
