import dayjs from "dayjs";
import { FunctionField } from "react-admin";
import type { FieldProps } from "react-admin";
import type { Device } from "../types";
import { useLastDeviceConfig } from "./DeviceConfig";

export default function DeviceModeField(props: FieldProps<Device>) {
  const lastDeviceConfig = useLastDeviceConfig();
  return (
    <FunctionField<Device>
      render={() =>
        lastDeviceConfig
          ? `${lastDeviceConfig.mode} (for ${dayjs
              .duration(dayjs().diff(lastDeviceConfig.createdAt))
              .humanize()})`
          : ""
      }
      variant="body1"
      {...props}
    />
  );
}
