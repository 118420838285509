import { ExportIcon, useExport } from "../../../actions/useExport";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const ExportDevicesOrEndUsers: React.FC<{
  resource: "devices" | "end-users";
}> = ({ resource }) => {
  const { mutate } = useExport((filter) =>
    dataProvider.exportData(resource, filter)
  );
  return (
    <ActionMenuItem
      label="Export Deployment"
      icon={<ExportIcon />}
      onClick={mutate}
    />
  );
};
