import Icon from "@mui/icons-material/DoDisturb";
import { useRecordContext } from "react-admin";
import type { DemandResponse } from "../types";

export const CancelledField: React.FC<any> = (props) => {
  const record = useRecordContext<DemandResponse>();

  if (!record?.cancelledAt) {
    return null;
  }

  return <Icon color="error" {...props} />;
};
