import { HowToReg } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  DateInput,
  Form,
  NullableBooleanInput,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../layout/actions-menu";
import { useAxiosMutation } from "../../../providers/data-hooks/useAxiosQuery";
import type { EndUser } from "../type";

export function EditConsents() {
  const record = useRecordContext<EndUser>();
  const refresh = useRefresh();
  const { mutate } = useAxiosMutation(
    {
      method: "PUT",
      url: `/end-users/${record.id}/consents`,
    },
    { onSuccess: () => refresh() }
  );

  return (
    <DialogActionMenuItem
      label="Edit Consents"
      icon={<HowToReg />}
      dialog={
        <ActionDialog>
          <Form
            record={record.consents}
            defaultValues={record.consents}
            onSubmit={(data) => {
              mutate(data);
            }}
          >
            <DialogTitle>Edit Consents</DialogTitle>
            <DialogContent>
              <Grid container>
                {[
                  {
                    label: "Consent to terms of service",
                    source: "TERMS_OF_SERVICES",
                  },
                  {
                    label: "Consent to device data collection",
                    source: "DEVICE_DATA_COLLECTION",
                  },
                  {
                    label: "Consent to demand response",
                    source: "DEMAND_RESPONSE",
                  },
                  {
                    label: "Consent to electricity data collection",
                    source: "ELECTRICITY_DATA_COLLECTION",
                  },
                ].map(({ label, source }) => (
                  <Grid item sm={12}>
                    <Typography>{label}</Typography>
                    <NullableBooleanInput
                      label="Consent"
                      source={`${source}.consent`}
                      sx={{ margin: 1 }}
                    />
                    <DateInput
                      label="Date"
                      source={`${source}.date`}
                      defaultValue={new Date()}
                      sx={{ margin: 1 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <ActionDialogButton>Cancel</ActionDialogButton>
              <ActionDialogButton type="submit" variant="contained">
                Submit
              </ActionDialogButton>
            </DialogActions>
          </Form>
        </ActionDialog>
      }
    />
  );
}
