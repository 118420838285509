import { ReferenceInput, SelectInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { IncidentActor } from "../../types";

interface MaintenanceProviderInputProps {
  source: string;
}

export const MaintenanceProviderInput: React.FC<
  MaintenanceProviderInputProps
> = ({ source }) => {
  const assignedTo = useWatch({ name: "assignedTo" });

  if (assignedTo !== IncidentActor.MAINTENANCE_PROVIDER) {
    return null;
  }

  return (
    <ReferenceInput reference="maintenance-providers" source={source}>
      <SelectInput fullWidth label="Maintenance Provider" />
    </ReferenceInput>
  );
};
