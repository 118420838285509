import { Typography } from "@mui/material";
import { DailySavings } from "./DailySavings";
import { Energies } from "./Energies";
import { SavingsTable } from "./Savings";

export const EnergyTab = () => {
  return (
    <>
      <SavingsTable />
      <Typography variant="h5" marginTop={4}>
        Daily energies
      </Typography>
      <Energies />
      <Typography variant="h5" marginTop={4}>
        Daily savings
      </Typography>
      <DailySavings />
    </>
  );
};
