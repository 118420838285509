import { TablePagination } from "@mui/material";
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  WithRecord,
  usePaginationState,
  useRecordContext,
} from "react-admin";
import { useDeviceDailyStats } from "../../../../../providers/data-hooks/useDeviceDailyStats";
import type { DailyStats, Device } from "../../../types";

export const Energies = () => {
  const device = useRecordContext<Device>();
  const { data, isLoading } = useDeviceDailyStats(device.id);
  const { page, setPage, perPage } = usePaginationState({ perPage: 30 });

  return (
    <>
      <Datagrid
        data={data?.slice((page - 1) * perPage, page * perPage)}
        isLoading={isLoading}
        sort={{ field: "day", order: "ASC" }}
        bulkActionButtons={false}
      >
        <DateField source="day" label="Date" />
        <TextField source="energy" label="Energy (Wh)" />
        <BooleanField label="Energy Issue" source="has_energy_issue" />
        <WithRecord<DailyStats>
          label="Whole Day Missing"
          render={(record) => (
            <BooleanField
              record={{
                ...record,
                whole_day_missing: record.data_coverage === 0,
              }}
              source="whole_day_missing"
            />
          )}
        />
        <FunctionField<DailyStats>
          source="inconsistent_data_coverage"
          label="Inconsistent Coverage (seconds)"
          render={(record) => Math.round(record.inconsistent_data_coverage)}
        />
        <FunctionField<DailyStats>
          source="data_coverage"
          label="Data Coverage (hours)"
          render={(record) => Math.round(record.data_coverage / 60 / 60)}
        />
        <FunctionField<DailyStats>
          source="tank_usage_ratio"
          label="Usage ratio"
          render={(record) =>
            record.tank_usage_ratio == null
              ? "N/A"
              : `${Math.round(record.tank_usage_ratio * 100) / 100}`
          }
        />
      </Datagrid>
      <TablePagination
        count={data?.length || 0}
        page={page - 1}
        onPageChange={(event, newPage) => setPage(newPage + 1)}
        rowsPerPage={perPage}
        rowsPerPageOptions={[perPage]}
      />
    </>
  );
};
