import { useRecordContext } from "react-admin";

export const ContractTypeField: React.FC<{ label?: string }> = () => {
  const record = useRecordContext();

  if (!record?.HoraireHCHP) {
    return <>Unknown</>;
  }

  if (record.HoraireHCHP.length === 0) {
    return <>Base</>;
  }
  return <>HC/HP</>;
};
