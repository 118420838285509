import EmailIcon from "@mui/icons-material/Email";
import { useRecordContext } from "react-admin";
import { DialogActionMenuItem } from "../../../layout/actions-menu";
import { SendIncidentToLandlordDialog } from "../edit/SendIncidentToLandlordDialog";
import type { Incident } from "../types";
import { IncidentActor } from "../types";

export const SendIncidentToLandlordButton = () => {
  const record = useRecordContext<Incident>();

  const disabled = record.assignedTo !== IncidentActor.LANDLORD;

  return (
    <DialogActionMenuItem
      label="Send to landlord"
      icon={<EmailIcon />}
      dialog={<SendIncidentToLandlordDialog />}
      disabled={disabled}
    />
  );
};
