import CreateIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  useCreatePath,
  useDeleteWithUndoController,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { ActionMenuItem } from "./ActionMenuItem";

interface EditActionProps {
  label?: string;
}

export const EditAction: React.FC<EditActionProps> = ({ label }) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const createPath = useCreatePath();
  const navigate = useNavigate();

  if (!record) return null;

  return (
    <ActionMenuItem
      onClick={() =>
        navigate(createPath({ type: "edit", resource, id: record.id }))
      }
      label={label ?? "Edit"}
      icon={<EditIcon />}
    />
  );
};

export const CreateAction = () => {
  const resource = useResourceContext();
  const createPath = useCreatePath();
  const navigate = useNavigate();

  return (
    <ActionMenuItem
      onClick={() => navigate(createPath({ type: "create", resource }))}
      label="Create"
      icon={<CreateIcon />}
    />
  );
};

export const DeleteAction = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const { handleDelete } = useDeleteWithUndoController({
    record,
    resource,
    redirect: "list",
  });

  if (!record || record.id == null) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={handleDelete}
      label="Delete"
      icon={<DeleteIcon />}
    />
  );
};
