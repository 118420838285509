import Icon from "@mui/icons-material/Home";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { useMutation } from "react-query";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";
import { removalResponsibleChoices } from "../../../intervention/fields/RemovalResponsibleInput";
import type { Device } from "../../types";

export const UninstallButton = () => {
  const record = useRecordContext<Device>();
  const refresh = useRefresh();
  const notify = useNotify();
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [comment, setComment] = useState("");
  const [removalResponsible, setRemovalResponsible] = useState("");

  const { mutate: uninstall } = useMutation(
    ["uninstall", record?.id],
    () => {
      if (!record) throw new Error("Can't uninstall an unknown device");
      return dataProvider.uninstallDevice(
        record.id,
        date,
        comment,
        removalResponsible
      );
    },
    {
      onSuccess: () => {
        notify("Device uninstalled", { type: "success" });
        refresh();
      },
      onError: (error: any) => {
        notify(`Failed to uninstall device: ${error.message}`, {
          type: "error",
        });
      },
    }
  );

  return (
    <DialogActionMenuItem
      label="Uninstall"
      icon={<Icon />}
      dialog={
        <ActionDialog fullWidth>
          <DialogTitle>Uninstall device</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={date}
                  onChange={(event) => {
                    event.target.value && setDate(event.target.value);
                  }}
                  type="date"
                  label="Intervention Date"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <InputLabel>Removal Responsible</InputLabel>
                  <Select
                    fullWidth={true}
                    value={removalResponsible}
                    label="Removal Responsible"
                    onChange={(event) => {
                      setRemovalResponsible(event?.target?.value ?? "");
                    }}
                    required={true}
                  >
                    <MenuItem value="">No responsible</MenuItem>
                    {removalResponsibleChoices.map(({ name, id }) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  value={comment}
                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                  fullWidth
                  multiline
                  minRows={3}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              disabled={!date || !comment || !removalResponsible}
              onClick={(_, onClose) => {
                onClose();
                uninstall();
              }}
            >
              Uninstall
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
