import { EnumField } from "../../../fields/EnumField";
import { housingStatusColors } from "../types";

export const HousingStatusField: React.FC<{
  label?: string;
  source?: string;
}> = ({ label }) => (
  <EnumField
    label={label || "Status"}
    source="status"
    colors={housingStatusColors}
  />
);
