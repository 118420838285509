import { EnumField } from "../../../fields/EnumField";
import { interventionTypeColors } from "../types";

export const InterventionTypeField: React.FC<{ label?: string }> = ({
  label,
}) => (
  <EnumField
    label={label || "Type"}
    source="type"
    colors={interventionTypeColors}
  />
);
