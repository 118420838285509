// in src/users.js
import {
  Datagrid,
  DateField,
  DeleteButton,
  EmailField,
  List,
  ReferenceManyCount,
  TextField,
} from "react-admin";

export const UserList = () => (
  <List perPage={10} hasCreate>
    <Datagrid bulkActionButtons={false}>
      <EmailField source="email" />
      <TextField source="role" />
      <DateField source="createdAt" label="Creation Date" />
      <ReferenceManyCount
        reference="groups"
        target="groupOwner"
        label="Groups"
      />
      <ReferenceManyCount reference="devices" target="owner" label="Devices" />
      <DeleteButton />
    </Datagrid>
  </List>
);
