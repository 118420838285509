import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useBulkActionFilter } from "../../../actions/useBulkActionFilter";
import { useNotifyAction } from "../../../actions/useNotifyAction";
import { ResourceSelect } from "../../../layout/ResourceSelect";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";
import { FirmwareIcon } from "../../firmware";

export const AssignFirmware: React.FC = () => {
  const [selectedFirmwareVersion, setSelectedFirmware] = useState<string>();
  const filter = useBulkActionFilter();

  const {
    mutate: updateGroupDevices,
    isLoading: isSaving,
    error: saveError,
  } = useNotifyAction(
    () =>
      dataProvider.bulkUpdate("devices", filter, {
        targetFirmwareVersion: selectedFirmwareVersion,
      }),
    {
      successMessage: `Firmware version assigned`,
      errorPrefixMessage: `Failed to assign firmware version`,
    }
  );

  return (
    <DialogActionMenuItem
      label="Assign Firmware"
      icon={<FirmwareIcon />}
      dialog={
        <ActionDialog fullWidth>
          <DialogTitle>Assign Firmware</DialogTitle>
          <DialogContent>
            <ResourceSelect
              resource="firmwares"
              sort={{ field: "version", order: "DESC" }}
              label="Choose a target firmware version"
              labelField="version"
              valueField="version"
              onChange={(value) => setSelectedFirmware(value)}
              sx={{ marginTop: 1 }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            {saveError ? (saveError as any).message : null}
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              disabled={!selectedFirmwareVersion || isSaving}
              onClick={(_, onClose) => {
                onClose();
                updateGroupDevices();
              }}
            >
              Assign Firmware
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
