import type { FunctionFieldProps } from "react-admin";
import type { Except } from "type-fest";
import { EnumField } from "../../../fields/EnumField";
import { ProjectStage, projectStageLabels } from "../type";

export const ProjectStageField: React.FC<
  { label?: string } & Except<FunctionFieldProps, "render">
> = ({ label, ...props }) => (
  <EnumField
    {...props}
    label={label || "Stage"}
    source="deployment.stage"
    colors={{
      [ProjectStage.NOT_STARTED_YET]: "#9e9e9e",
      [ProjectStage.DEPLOYMENT_PREPARATION]: "#795548",
      [ProjectStage.DEPLOYMENT_IN_PROGRESS]: "#00bcd4",
      [ProjectStage.DEPLOYMENT_DONE]: "#3f51b5",
    }}
    labels={projectStageLabels}
  />
);
