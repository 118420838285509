import ListingIcon from "@mui/icons-material/ListAlt";
import { IconButton } from "@mui/material";
import { useRecordContext } from "react-admin";
import type { MaintenanceProvider } from "../type";

interface SpreadhseetFieldProps {
  label?: string;
}
export const MaintenanceSpreadsheetField: React.FC<
  SpreadhseetFieldProps
> = () => {
  const record = useRecordContext<MaintenanceProvider>();

  if (!record) {
    return null;
  }

  return (
    <IconButton
      href={`https://docs.google.com/spreadsheets/d/${record.maintenanceSpreadsheetId}`}
      target="_blank"
      rel="noreferrer"
      disabled={!record.maintenanceSpreadsheetId}
    >
      <ListingIcon />
    </IconButton>
  );
};
