import dayjs from "dayjs";
import type { SeriesLineOptions } from "highcharts";
import { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { dataProvider } from "../../providers/data";
import { TemperatureChart } from "../devices/show/tabs/data/charts/Temperature";
import { getScheduleSeries } from "../devices/show/tabs/data/charts/schedule-series";
import { useChartParams } from "../devices/show/tabs/data/charts/useChartParams";
import { SeriesName } from "../devices/types";
import type { Device } from "../devices/types";
import { Mode } from "./types";

interface SchedulePreviewProps {
  device: Device;
}

const startDate = dayjs().subtract(7, "days").toDate();
const endDate = new Date();

export const SchedulePreview: React.FC<SchedulePreviewProps> = ({ device }) => {
  const mode = useWatch<{ mode: Mode }>({ name: "mode" });
  return [
    Mode.SMART,
    Mode.SMART_FLEX,
    Mode.SMART_FLEX_v2,
    Mode.SMART_ENERGY,
  ].includes(mode) ? (
    <SchedulePreviewBase device={device} />
  ) : null;
};

const SchedulePreviewBase: React.FC<SchedulePreviewProps> = ({ device }) => {
  const parameters = useWatch({ name: "parameters" });
  const mode = useWatch<{ mode: Mode }>({ name: "mode" });

  const debouncedParameters = useDebounce(parameters, 5000);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["smart-schedule", device.id, mode, debouncedParameters],
    queryFn: () => {
      return dataProvider.getSmartSchedule({
        deviceId: device.id,
        mode,
        parameters: debouncedParameters,
      });
    },
    staleTime: 1000 * 60 * 2,
  });

  const { dateRange, displayedSeries, handleParamsOptions } = useChartParams([
    SeriesName.T1_AVG,
    SeriesName.T2_AVG,
    SeriesName.SCHEDULE,
    SeriesName.CONFIG_ACK,
    SeriesName.CONFIG,
    SeriesName.ENERGY_CUMULATIVE,
    SeriesName.ENERGY,
  ]);

  const additionalSeries = useMemo(() => {
    if (!data) {
      return [];
    }

    const series: SeriesLineOptions = {
      name: "Computed Schedule",
      data: getScheduleSeries(data.weeklyInstructions, startDate, endDate),
      step: "left",
      type: "line",
      id: "schedule",
      gapSize: 0,
      color: "#8bc34a",
      selected: true,
    };

    return [series];
  }, [data]);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <TemperatureChart
        title="Preview Schedule"
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        displayedSeries={displayedSeries}
        paramsOptions={handleParamsOptions}
        device={device}
        isFetching={
          isLoading || isFetching || debouncedParameters !== parameters
        }
        additionalSeries={additionalSeries}
      />
    </div>
  );
};

// see https://github.com/tannerlinsley/react-query/issues/293
// see https://usehooks.com/useDebounce/
export default function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
