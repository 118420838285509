import Both from "@mui/icons-material/AlignVerticalBottom";
import Right from "@mui/icons-material/HdrStrong";
import Left from "@mui/icons-material/HdrWeak";
import {
  Box,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import type Highcharts from "highcharts";
import { useState } from "react";
import { useQuery } from "react-query";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";

interface HealthRiskProps {
  filter: any;
}

export const HealthRisk: React.FC<HealthRiskProps> = ({ filter }) => {
  const { data } = useQuery({
    queryFn: () => dataProvider.getDevicesHealthRisks(filter),
    queryKey: [`devices/health-risks`, filter],
  });
  const [hidePilot, setHidePilot] = useState([true, true]);

  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Évaluation des risques sanitaires selon la variation des températures de consigne",
    },
    xAxis: {
      categories: ["Installation", "Installation J+5", "En pilotage"],
    },
    yAxis: {
      title: {
        text: "%",
      },
    },
    plotOptions: {
      series: {
        tooltip: {
          valueDecimals: 1,
          valueSuffix: "%",
        },
      },
    },
    series: [
      {
        name: "🔥 Risque de brûlure (> 70°C)",
        color: "#e22401",
        type: "column",
        data: data && [
          {
            y: hidePilot[0]
              ? (data.data.observation.burns / data.data.devicesCount) * 100
              : 0,
          },
          {
            y: hidePilot[0]
              ? (data.data.observation.burns / data.data.devicesCount) * 100
              : 0,
          },
          {
            y: hidePilot[1]
              ? (data.data.pilot.burns / data.data.devicesCount) * 100
              : 0,
          },
        ],
      },
      {
        name: "🦠 Risque de légionellose (< 55°C)",
        color: "#35c7fc",
        type: "column",
        data: data && [
          {
            y: hidePilot[0]
              ? (data.data.observation.legionellosis / data.data.devicesCount) *
                100
              : 0,
          },
          {
            y: hidePilot[0]
              ? ((data.data.observation.legionellosis -
                  (data.data.observation.legionellosisCountByCause.thermostat ??
                    0)) /
                  data.data.devicesCount) *
                100
              : 0,
          },
          {
            y: hidePilot[1]
              ? (data.data.pilot.legionellosis / data.data.devicesCount) * 100
              : 0,
          },
        ],
      },
    ],
  };

  return (
    <Grid item xs={12} md={12}>
      <Paper elevation={1}>
        <Chart options={options} isLoading={!data} />
        <Box display="flex" justifyContent="center" paddingBottom={1}>
          <ToggleButtonGroup
            size="small"
            exclusive
            value={hidePilot}
            onChange={(_, v) => setHidePilot(v)}
          >
            <ToggleButton
              value={[true, false]}
              selected={hidePilot[0] && !hidePilot[1]}
            >
              <Left />
            </ToggleButton>
            <ToggleButton
              value={[true, true]}
              selected={hidePilot[1] && hidePilot[0]}
            >
              <Both />
            </ToggleButton>
            <ToggleButton
              value={[false, true]}
              selected={hidePilot[1] && !hidePilot[0]}
            >
              <Right />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Paper>
    </Grid>
  );
};
