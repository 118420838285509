import { Typography } from "@mui/material";
import { NumberField, useRecordContext } from "react-admin";
import type { NumberFieldProps } from "react-admin";

export const RoundedNumberField = ({
  unit,
  ...props
}: { unit?: string } & NumberFieldProps) => {
  const record = useRecordContext();
  return (
    <>
      <NumberField
        emptyText="-"
        transform={(x) => Math.floor(x * 100) / 100}
        variant="body1"
        {...props}
      />
      {unit && props.source && record && record[props.source] != null && (
        <Typography variant="body1">&nbsp;{unit}</Typography>
      )}
    </>
  );
};
