import TimerIcon from "@mui/icons-material/Timer";
import { Box } from "@mui/material";

export const OngoingFilter: React.FC<{
  label: string;
  source: string;
  defaultValue: boolean;
}> = () => (
  <Box
    sx={{
      backgroundColor: "#ff980052",
      color: "#ff9800",
      borderRadius: 1,
      textAlign: "center",
      fontWeight: "bold",
      paddingX: 1,
      paddingY: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "min-content",
      marginY: "12px",
    }}
  >
    <TimerIcon fontSize="small" />
    ONGOING
  </Box>
);
