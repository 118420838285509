import { Button, CardContent, TextField } from "@mui/material";
import { useState } from "react";
import { Login, useNotify } from "react-admin";
import { useMutation } from "react-query";
import { dataProvider } from "../../providers/data";

export const ForgotPassword = () => {
  const notify = useNotify();

  const [email, setEmail] = useState("");
  const mutation = useMutation({
    mutationFn: async () => {
      try {
        await dataProvider.forgotPassword(email);
        notify("An email has been sent with a link to reset password");
      } catch (error: any) {
        notify(`An error occured: ${error.message}`, { type: "error" });
      }
    },
  });

  return (
    <Login>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          type="email"
          size="small"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ marginY: 2 }}
          onClick={() => {
            mutation.mutate();
          }}
          disabled={mutation.isLoading}
        >
          Forgot Password
        </Button>
      </CardContent>
    </Login>
  );
};
