import Icon from "@mui/icons-material/Engineering";
import { useExport } from "../../../../actions/useExport";
import { ActionMenuItem } from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";

export const ExportIncidents = () => {
  const { mutate } = useExport((filter) =>
    dataProvider.exportIncidents(filter)
  );
  return (
    <ActionMenuItem
      label="Export Filtered Incidents"
      icon={<Icon />}
      onClick={mutate}
    />
  );
};
