import { DialogActions, DialogTitle } from "@mui/material";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "./ActionDialog";
import type { ActionMenuItemProps } from "./ActionMenuItem";

export const ConfirmActionMenuItem: React.FC<ActionMenuItemProps> = ({
  onClick,
  ...props
}) => {
  return (
    <DialogActionMenuItem
      {...props}
      dialog={
        <ActionDialog>
          <DialogTitle>Confirm</DialogTitle>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton variant="contained" onClick={onClick}>
              Confirm
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
