import { get } from "lodash";
import { useRecordContext } from "react-admin";

export const SatisfiedField: React.FC<{
  source: string;
  label?: string;
}> = ({ source }) => {
  const record = useRecordContext();

  const value = get(record, source);

  return (
    <div
      style={{
        backgroundColor:
          value === true
            ? "#4caf508c"
            : value === false
            ? "#f44336a1"
            : "#dadada",
        borderRadius: 5,
        padding: `2px 5px`,
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value === true ? "Yes" : value === false ? "No" : "-"}
    </div>
  );
};
