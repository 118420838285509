import type { Color } from "@mui/material";
import {
  blue,
  blueGrey,
  cyan,
  deepPurple,
  green,
  lightGreen,
  lime,
  pink,
  purple,
  red,
  teal,
} from "@mui/material/colors";
import type { RaRecord } from "react-admin";
import type { AlertCode } from "../alerts/types";
import type {
  InterventionAccounting,
  InterventionPriority,
} from "../intervention/types";

export interface Incident extends RaRecord {
  id: string;
  deviceId: string;
  status: IncidentStatus;
  assignedTo?: IncidentActor;
  events: IncidentEvent[];
  tags: string[];
  type: AlertCode;
  dueDate?: Date;
}

export enum IncidentStatus {
  OPENED = "OPENED",
  PROCESSING = "PROCESSING",
  SOLVED = "SOLVED",
  CLOSED = "CLOSED",
}

export enum IncidentActor {
  MAINTENANCE_PROVIDER = "MAINTENANCE_PROVIDER",
  RESIDENT = "RESIDENT",
  LANDLORD = "LANDLORD",
  DATA_TEAM = "DATA_TEAM",
  TECH_TEAM = "TECH_TEAM",
  OPS_TEAM = "OPS_TEAM",
}

export const incidentTag: Record<
  string,
  { label: string; description: string; color: Color }
> = {
  NO_RESIDENT_CONTACT: {
    label: "Résident injoignable",
    color: red,
    description: "Impossible de contacter le résident",
  },
  COMMUNICATION_BUG: {
    label: "Bug communication",
    color: green,
    description:
      "Problème de communication du boîtier qui perturbe les alertes et l'algo",
  },
  MISSING_CONTACT: {
    label: "Coordonnées manquantes",
    color: blue,
    description:
      "Actions nécessaires pour les CSM => contact auprès du bailleur",
  },
  PILOTABLE_HEATER: {
    label: "Chauffe-eau pilotable",
    color: blueGrey,
    description:
      "Le résident peut éteindre le chauffe-eau au niveau du ballon (après notre boîtier) = Notre boîtier n'est pas éteint et des alertes remontent. Le résident peut gérer la température du chauffe-eau manuellement",
  },
  PILOT_REFUSAL: {
    label: "Refus du pilotage",
    color: lightGreen,
    description:
      "Le résident refuse que l'on pilote la consommation du chauffe-eau",
  },
  LANDLORD_MANAGEMENT: {
    label: "Gestion bailleur",
    color: pink,
    description:
      "Le bailleur gère à la place du locataire l'installation et les maintenances Il faudra donc le contacter directement pour tous les besoins OPS liés au device",
  },
};

export const incidentActorLabels: Record<
  IncidentActor,
  { color: Color; label: string }
> = {
  [IncidentActor.MAINTENANCE_PROVIDER]: {
    color: teal,
    label: "Maintenance Provider",
  },
  [IncidentActor.RESIDENT]: {
    color: lime,
    label: "Resident",
  },
  [IncidentActor.LANDLORD]: {
    color: cyan,
    label: "Landlord",
  },
  [IncidentActor.DATA_TEAM]: {
    color: deepPurple,
    label: "Data Team",
  },
  [IncidentActor.TECH_TEAM]: { color: purple, label: "Dev Team" },
  [IncidentActor.OPS_TEAM]: { color: red, label: "Ops Team" },
};

export interface IncidentEvent {
  datetime: Date;
  assignedTo: IncidentActor;
  comment?: string;
  action?: string;
  priority?: InterventionPriority;
  interventionAccounting?: InterventionAccounting;
  origin: string;
}
