import { useRecordContext } from "react-admin";

export const PipedriveLink: React.FC<{
  size?: number;
  label?: string;
  source: string;
  resource: "organization" | "projects" | "deal";
}> = ({ size = 30, source, resource }) => {
  const record = useRecordContext();

  if (!record || !record[source]) {
    return null;
  }

  return (
    <a
      href={`https://elaxenergie2.pipedrive.com/${resource}/${record[source]}`}
      target="_blank"
      rel="noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 512 512"
        fill="none"
        style={{ display: "block" }}
      >
        <path fill="#26292C" d="M0 0h512v512H0z" />
        <path
          fill="#fff"
          d="M280.458 95c-35.784 0-56.533 16.25-66.456 27.084-1.203-9.63-7.518-21.968-32.176-21.968H128v55.974h21.952c3.608 0 4.811 1.203 4.811 4.815V417h63.749V314.08c9.924 9.028 28.868 21.668 58.638 21.668 62.246 0 105.848-49.354 105.848-120.374C383.299 143.45 341.802 95 280.458 95Zm-12.931 185.075c-34.28 0-49.917-32.802-49.917-63.497 0-48.15 26.162-65.303 50.82-65.303 30.07 0 50.518 25.88 50.518 64.701-.301 44.538-26.161 64.099-51.421 64.099Z"
        />
      </svg>
    </a>
  );
};
