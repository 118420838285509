import { Grid, Typography } from "@mui/material";
import { EnumFilter } from "../../filters/EnumFilter";
import { Mode } from "../../resources/device-config/types";
import { SelectOrganization } from "../../resources/devices/fields/SelectClient";
import { SelectProject } from "../../resources/devices/fields/SelectProject";
import { SelectZipCode } from "../../resources/devices/fields/SelectZipCode";
import { ContractType } from "../../resources/devices/types";
import { heatingStates } from "./widgets/HeaterState";

export const DashboardFilters = () => {
  return (
    <>
      <Grid container>
        <Grid container spacing={1} marginTop={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Clients & Géographie</Typography>
          </Grid>
          <Grid item xs={3}>
            <SelectOrganization label="Client" />
          </Grid>
          <Grid item xs={3}>
            <SelectProject label="Project" />
          </Grid>
          <Grid item xs={3}>
            <SelectZipCode />
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Chauffe-eaux</Typography>
          </Grid>
          <Grid item xs={3}>
            <EnumFilter
              label="Calcairisation"
              source="heaterData.state"
              categories={heatingStates.map((t) => ({
                value: t.selector,
                label: t.label,
              }))}
            />
          </Grid>
          <Grid item xs={3}>
            <EnumFilter
              label="Puissance"
              source="heaterData.computed_electric_power"
              categories={[
                { label: "< 1000", value: { $lt: 1000 } },
                {
                  label: "1000 - 2000",
                  value: { $gte: 1000, $lt: 2000 },
                },
                {
                  label: "2000 - 3000",
                  value: { $gte: 2000, $lt: 3000 },
                },
                { label: "> 3000", value: { $gte: 3000 } },
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <EnumFilter
              label="Volume"
              source="heaterData.volume"
              categories={[
                { label: "< 150", value: { $lte: 150 } },
                {
                  label: "151 - 200",
                  value: { $gt: 150, $lt: 200 },
                },
                {
                  label: "201 - 300",
                  value: { $gt: 200, $lt: 300 },
                },
                {
                  label: "> 300",
                  value: { $gt: 300 },
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={1}>
        <Grid item xs={12}>
          <Typography variant="h6">Boîtiers</Typography>
        </Grid>
        <Grid item xs={3}>
          <EnumFilter
            label="Mode de pilotage actuel"
            source="mode"
            categories={Object.values(Mode).map((t) => ({
              value: t,
              label: t,
            }))}
          />
        </Grid>
        <Grid item xs={3}>
          <EnumFilter
            label="HC/HP / Base"
            source="contractType"
            categories={Object.values(ContractType).map((t) => ({
              value: t,
              label: t,
            }))}
          />
        </Grid>
        <Grid item xs={3}>
          <EnumFilter
            label="Économies"
            source="annualSavingsInEuro"
            categories={[
              { label: "+38.5€/an", value: { $gte: 38.5 } },
              { label: "-38.5€/an", value: { $lt: 38.5, $gt: 0 } },
              { label: "< 0€/an", value: { $lte: 0 } },
              { label: "Non calculé", value: { $exists: false } },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <EnumFilter
            label="Régularité des horaires"
            source="is_pilotable"
            categories={[
              { label: "HP/HC ou base régulier", value: true },
              {
                label:
                  "Irrégulier avec taux de couverture insuffisant pour pilotage",
                value: false,
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};
