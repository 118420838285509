import { Grid, Typography } from "@mui/material";
import { Link, ReferenceManyCount, useRecordContext } from "react-admin";
import { InterventionsFlow } from "../../../layout/dashboard/widgets/InterventionsFlow";
import { DeploymentHousingList } from "../../housing/renderers/DeploymentHousingList";
import { InterventionType, InterventionStatus } from "../../intervention/types";
import { DeploymentProgress } from "../../project/fields/DeploymentProgress";
import type { Deal } from "../type";

export const DealDeploymentTab = () => {
  const record = useRecordContext<Deal>();

  return (
    <>
      <DeploymentProgress entity="deal" />
      <InterventionsFlow dealId={record.id} />
      <Grid container spacing={2} paddingTop={2}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>
            <Link
              to={`/interventions?filter=${JSON.stringify({
                dealId: record.id,
                type: InterventionType.INSTALLATION,
                status: InterventionStatus.SUCCESS,
              })}`}
            >
              <ReferenceManyCount
                reference="interventions"
                target="dealId"
                filter={{
                  type: InterventionType.INSTALLATION,
                  status: InterventionStatus.SUCCESS,
                }}
              />{" "}
              installations réussies
            </Link>
            {" / "}
            <Link
              to={`/devices?filter=${JSON.stringify({ dealId: record.id })}`}
            >
              <ReferenceManyCount reference="devices" target="dealId" /> devices
              (à date)
            </Link>
            {" / "}
            <Link
              to={`/housings?filter=${JSON.stringify({ dealId: record.id })}`}
            >
              <ReferenceManyCount reference="housings" target="dealId" />{" "}
              housings
            </Link>
            {" / "}
            <Link
              to={`/interventions?filter=${JSON.stringify({
                dealId: record.id,
              })}`}
            >
              <ReferenceManyCount reference="interventions" target="dealId" />{" "}
              interventions
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <DeploymentHousingList target="dealId" />
    </>
  );
};
