import { Box, Typography } from "@mui/material";
import type { ReactNode } from "react";

interface InlineLabelProps {
  label: string;
  children: ReactNode | ReactNode[];
}

export const InlineLabel: React.FC<InlineLabelProps> = ({
  label,
  children,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" fontWeight="bold" marginRight={1}>
        {label}:
      </Typography>

      {children}
    </Box>
  );
};
