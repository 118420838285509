import {
  Datagrid,
  DateField,
  NumberField,
  Pagination,
  ReferenceManyField,
  ShowButton,
  TextField,
  useRecordContext,
} from "react-admin";
import { AddressField } from "../../../../housing/fields/Address";
import { WithResidenceProjectDetails } from "../../../../residence/fields/WithResidenceProjectDetails";

export const ProjectResidences = () => {
  const project = useRecordContext();
  return (
    <ReferenceManyField
      reference="residences"
      target="projects.projectId"
      perPage={100}
      pagination={<Pagination />}
    >
      <Datagrid
        bulkActionButtons={false}
        sort={{ field: "esi", order: "DESC" }}
      >
        <TextField label="ESI" source="esi" />
        <WithResidenceProjectDetails label="Zone" projectId={project.id}>
          <TextField source="zone" />
        </WithResidenceProjectDetails>
        <AddressField label="Address" />
        <WithResidenceProjectDetails
          label="Deployment start date"
          projectId={project.id}
        >
          <DateField source="deploymentStartFormattedDate" />
        </WithResidenceProjectDetails>
        <WithResidenceProjectDetails
          label="Deployment end date"
          projectId={project.id}
        >
          <DateField source="deploymentEndFormattedDate" />
        </WithResidenceProjectDetails>
        <NumberField label="Housings count" source="housingCount" />
        <NumberField
          label="Equipped housings count"
          source="equippedHousingCount"
        />
        <NumberField
          label="Letters sent"
          source={`deploymentCommunicationsCountByProject.${project.id}.letter`}
        />
        <NumberField
          label="Emails sent"
          source={`deploymentCommunicationsCountByProject.${project.id}.email`}
        />
        <ShowButton resource="residences" />
      </Datagrid>
    </ReferenceManyField>
  );
};
