import { Box } from "@mui/material";
import { blue } from "@mui/material/colors";

export const FixedValueFilter: React.FC<{
  label: string;
  source: string;
  defaultValue: any;
  children?: any;
  color?: string;
}> = ({ color, label, children }) => {
  return (
    <Box
      sx={{
        color: color ?? blue["400"],
        border: `solid 1px ${color ?? blue["400"]}`,
        borderRadius: 1,
        textAlign: "center",
        fontWeight: "bold",
        paddingX: 1,
        paddingY: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "min-content",
        marginY: "12px",
      }}
    >
      {children ?? label}
    </Box>
  );
};
