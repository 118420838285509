import FilterIcon from "@mui/icons-material/FilterList";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { cloneElement, useState } from "react";
import { useStore } from "react-admin";
import type { FrameType } from "./shared";
import { frameSpecs } from ".";

export const FrameFilters = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <FilterIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {(Object.keys(frameSpecs) as FrameType[]).map((type) => (
          <FrameFilter type={type} key={type} />
        ))}
      </Menu>
    </>
  );
};

const FrameFilter: React.FC<{ type: FrameType }> = ({ type }) => {
  const [isVisible, setIsVisible] = useFrameFilter(type);

  return (
    <MenuItem
      selected={isVisible}
      onClick={() => {
        setIsVisible((v) => !v);
      }}
    >
      <ListItemIcon>
        {cloneElement(
          frameSpecs[type].icon,
          isVisible ? {} : { sx: { color: "#9e9e9e" } }
        )}
      </ListItemIcon>
      <Typography variant="inherit">{type} frames</Typography>
    </MenuItem>
  );
};

const useFrameFilter = (type: FrameType) => {
  return useStore(`frames.${type}.visible`, true);
};

export const useFrameTypeData = <Frame extends object, T extends FrameType>(
  type: T,
  frames?: Frame[]
) => {
  const [isVisible] = useFrameFilter(type);

  if (!isVisible || !frames) {
    return [];
  }

  return frames.map((f) => ({
    type,
    frame: f,
  }));
};
