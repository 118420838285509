import Icon from "@mui/icons-material/PublishedWithChanges";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { useMutation } from "react-query";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";
import { SelectDevice } from "../../fields/SelectDevice";
import { DeviceStatus } from "../../types";
import type { Device } from "../../types";

export const ReplaceButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [comment, setComment] = useState("");

  const { mutate: replace } = useMutation(
    ["replace", record?.id],
    () =>
      dataProvider.replaceDevice({
        replacedDeviceId: record.id as string,
        deviceId: selectedDevice?.id as string,
        date: date,
        comment,
      }),
    {
      onSuccess: () => {
        notify("Device uninstalled", { type: "success" });
        refresh();
      },
      onError: (error: any) => {
        notify(`Failed to replace device: ${error.message}`, {
          type: "error",
        });
      },
    }
  );

  return (
    <DialogActionMenuItem
      label="Replace"
      icon={<Icon />}
      dialog={
        <ActionDialog fullWidth>
          <DialogTitle>Replace this device by a new one</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={date}
                  onChange={(event) => {
                    event.target.value && setDate(event.target.value);
                  }}
                  type="date"
                  label="Intervention Date"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDevice
                  label="New Device"
                  value={selectedDevice}
                  onChange={setSelectedDevice}
                  filter={{ status: DeviceStatus.STOCK }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  value={comment}
                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                  fullWidth
                  multiline
                  minRows={3}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              disabled={!date || !comment}
              onClick={(_, onClose) => {
                onClose();
                replace();
              }}
            >
              Replace
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
