import { EnumField } from "../../../fields/EnumField";
import { zendeskTicketStatusColors } from "../type";

export const ZendeskTicketStatusField: React.FC<{ label?: string }> = ({
  label,
}) => (
  <EnumField
    label={label || "Status"}
    source="status"
    colors={zendeskTicketStatusColors}
  />
);
