import { useRecordContext } from "react-admin";
import type { EndUser } from "../type";

export const ContactsField: React.FC<{
  type: "emails" | "phoneNumbers";
  label?: string;
  emptyText?: string;
  sortable?: boolean;
}> = ({ type, emptyText = null }) => {
  const record = useRecordContext<EndUser>();

  if (!record) {
    return null;
  }

  const values = record.contacts?.[type]?.validated || [];

  return <>{values.length > 0 ? values.join(" / ") : emptyText}</>;
};
