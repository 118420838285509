import { Box } from "@mui/material";
import { Show, TextField } from "react-admin";
import { FieldItem } from "../../display/FieldItem";
import { InlineLabel } from "../../layout/InlineLabel";
import { communicationFields } from "./fields";
import { CommunicationStatusField } from "./fields/CommunicationStatus";

export const CommunicationShow = () => {
  return (
    <Show>
      <Box padding={2}>
        <InlineLabel label="Date">{communicationFields.date}</InlineLabel>
        <InlineLabel label="Topic">{communicationFields.topic}</InlineLabel>
        <InlineLabel label="Medium">{communicationFields.medium}</InlineLabel>
        <FieldItem label="Status" showHistory={true}>
          <CommunicationStatusField source="status" label="Status" />
        </FieldItem>
        <FieldItem label="Status Details" showHistory={true}>
          <TextField source="statusDetails" />
        </FieldItem>
        <InlineLabel label="Link">{communicationFields.externalId}</InlineLabel>
        <InlineLabel label="Housing">{communicationFields.housing}</InlineLabel>
        <InlineLabel label="Resident">
          {communicationFields.resident}
        </InlineLabel>
        <InlineLabel label="Project">{communicationFields.project}</InlineLabel>
        <InlineLabel label="Organization">
          {communicationFields.organization}
        </InlineLabel>
        <InlineLabel label="Maintenance Provider">
          {communicationFields.maintenanceProvider}
        </InlineLabel>
        <InlineLabel label="Intervention">
          {communicationFields.intervention}
        </InlineLabel>
      </Box>
    </Show>
  );
};
