import NotObserving from "@mui/icons-material/SignalWifiConnectedNoInternet4";
import Observing from "@mui/icons-material/Wifi";
import { useRecordContext } from "react-admin";
import type { Device } from "../types";

export const ObserveField: React.FC<{ label?: string }> = () => {
  const record = useRecordContext<Device>();

  if (!record || record.majorHWVersion === 1) {
    return null;
  }

  return record.isObserving ? <Observing /> : <NotObserving />;
};
