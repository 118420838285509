import { Typography } from "@mui/material";
import type { ReactNode } from "react";
import { RecordContextProvider, WithListContext } from "react-admin";
import type { RaRecord } from "react-admin";

export default function FunctionList<TRecord extends RaRecord>({
  render,
  emptyText,
}: {
  render: (record: TRecord) => ReactNode;
  emptyText?: ReactNode;
}) {
  return (
    <WithListContext<TRecord>
      render={({ data }) =>
        data && (
          <>
            {data.length > 0 ? (
              data.map((record) => (
                <RecordContextProvider key={record.id} value={record}>
                  {render(record)}
                </RecordContextProvider>
              ))
            ) : (
              <Typography variant="body1">{emptyText || "-"}</Typography>
            )}
          </>
        )
      }
    />
  );
}
