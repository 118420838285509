import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import type { ReactNode } from "react";
import { useCloseMenu } from "./context";

export interface ActionMenuItemProps {
  label: string;
  onClick: (event: any) => void;
  icon: ReactNode;
  dialogContent?: ReactNode;
  disabled?: boolean;
}

export const ActionMenuItem: React.FC<ActionMenuItemProps> = ({
  label,
  icon,
  onClick,
  disabled,
}) => {
  const closeMenu = useCloseMenu();

  return (
    <>
      <MenuItem
        onClick={(event) => {
          onClick(event);
          closeMenu();
        }}
        disabled={!!disabled}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </MenuItem>
    </>
  );
};
