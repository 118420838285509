import Sync from "@mui/icons-material/Sync";
import { useRecordContext } from "react-admin";
import { useNotifyAction } from "../../../actions/useNotifyAction";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const ApplyIntervention = () => {
  const record = useRecordContext();
  const { mutate } = useNotifyAction(
    () => dataProvider.applyIntervention(record.id as string),
    { errorPrefixMessage: "Failed to apply intervention" }
  );

  return <ActionMenuItem icon={<Sync />} label="Apply" onClick={mutate} />;
};
