import { Typography } from "@mui/material";
import { ReferenceOneField, TextField, useRecordContext } from "react-admin";
import type { TextFieldProps } from "react-admin";
import type { Housing } from "../types";

export default function EDEField(props: TextFieldProps<Housing>) {
  const record = useRecordContext(props);
  if (!record.pdl) return <Typography variant="body1">-</Typography>;
  return (
    <ReferenceOneField
      reference="demand-response-entities"
      source="pdl"
      target="pdl"
      link="show"
      emptyText="-"
    >
      <TextField source="name" variant="body1" />
    </ReferenceOneField>
  );
}
