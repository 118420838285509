import { Badge } from "@mui/material";
import { mapValues } from "lodash";
import { FunctionField } from "react-admin";
import { EnumField } from "../../../fields/EnumField";
import { codeToLabel } from "../../alerts/fields/AlertCodeField";
import type { Incident } from "../types";

interface IncidentTypeFieldProps {
  label?: string;
}

export const IncidentTypeField: React.FC<IncidentTypeFieldProps> = ({
  label,
}) => {
  return (
    <FunctionField<Incident>
      label={label ?? "Type"}
      render={(incident) => (
        <Badge
          badgeContent={incident?.device?.alerts.length}
          color={incident?.device?.alerts.length === 0 ? "success" : "error"}
          showZero
        >
          <EnumField
            source="type"
            colors={mapValues(codeToLabel, ({ color }) => color)}
            labels={mapValues(codeToLabel, ({ label }) => label)}
          />
        </Badge>
      )}
    />
  );
};
