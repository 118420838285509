import { Grid } from "@mui/material";
import { useRecordContext } from "react-admin";
import { AnnualSavings } from "../../../../layout/dashboard/widgets/AnnualSavings";

export const Savings = () => {
  const record = useRecordContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {record && <AnnualSavings filter={{ organizationId: record.id }} />}
      </Grid>
    </Grid>
  );
};
