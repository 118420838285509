import { Download, Upload } from "@mui/icons-material";
import type { SvgIconTypeMap } from "@mui/material";
import type { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { useRecordContext } from "react-admin";
import { DemandResponseType } from "../types";
import type { DemandResponse } from "../types";

export const DemandResponseTypeField = (
  props: { label?: string } & DefaultComponentProps<SvgIconTypeMap>
) => {
  const record = useRecordContext<DemandResponse>();

  const Icon = record?.type === DemandResponseType.DOWN ? Download : Upload;

  return (
    <Icon
      color={record?.type === DemandResponseType.DOWN ? "primary" : "warning"}
      {...props}
    />
  );
};
