import { Link, RecordContextProvider } from "react-admin";
import FunctionList from "../../../../../display/FunctionList";
import { DenseCommunication } from "../../../../communications/fields/DenseCommunication";
import type { Communication } from "../../../../communications/type";

export function CommunicationSummary() {
  return (
    <FunctionList<Communication>
      render={(communication) => (
        <RecordContextProvider value={communication}>
          <Link to={`/communications/${communication.id}/show`}>
            <DenseCommunication />
          </Link>
        </RecordContextProvider>
      )}
    />
  );
}
