import dayjs from "dayjs";
import { FunctionField } from "react-admin";
import type { FieldProps } from "react-admin";
import type { Device } from "../types";

export default function FirmwareVersionField(props: FieldProps<Device>) {
  return (
    <FunctionField<Device>
      render={(record) => {
        const isFirmwareUpToDate =
          record.targetFirmwareVersion === record.firmwareVersion ||
          !record.targetFirmwareVersion;

        const lastFirmwareUpdate =
          record.lastFirmwareUpdateRequest &&
          dayjs(new Date(record.lastFirmwareUpdateRequest)).format(
            "YYYY-MM-DD HH:mm"
          );

        return `${record.firmwareVersion}${
          isFirmwareUpToDate
            ? ""
            : ` (target is
    ${record.targetFirmwareVersion} ~ ${
                lastFirmwareUpdate
                  ? `last try at ${lastFirmwareUpdate}`
                  : "no OTA yet"
              })`
        }`;
      }}
      variant="body1"
      {...props}
    />
  );
}
