import { Paper } from "@mui/material";
import { useMeasure } from "react-use";
import { useAxiosQuery } from "../../../providers/data-hooks/useAxiosQuery";

interface MetabaseQuestionProps {
  questionId: number;
  filter?: Record<string, string | number>;
}

export const MetabaseQuestion: React.FC<MetabaseQuestionProps> = ({
  questionId,
  filter,
}) => {
  const { data } = useAxiosQuery<string>({
    url: `/metabase/question/${questionId}`,
    params: filter,
  });

  const [ref, { width }] = useMeasure<HTMLDivElement>();

  if (!data) {
    return null;
  }

  return (
    <Paper
      elevation={1}
      ref={ref}
      sx={{ display: "flex", flexGrow: 1, height: "400px" }}
    >
      <iframe src={data} frameBorder={0} width={width} height={400} />
    </Paper>
  );
};
