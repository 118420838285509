import { Autocomplete, TextField } from "@mui/material";
import { keyBy } from "lodash";
import { useGetList } from "react-admin";
import type { ResidenceContact } from "../types";

interface SelectResidenceContactProps {
  filter?: Record<string, any>;
  onChange: (residenceContact: ResidenceContact | null) => void;
}

export const SelectResidenceContact = ({
  filter,
  onChange,
}: SelectResidenceContactProps) => {
  const { data } = useGetList<ResidenceContact>("residence-contacts", {
    filter,
    pagination: { perPage: 10, page: 1 },
  });

  const residenceContactById = keyBy(data, "id");
  const residenceContactIds = Object.keys(residenceContactById);

  const getOptionLabel = (id: string) => {
    const contact = residenceContactById[id];
    return `${contact.firstName} ${contact.lastName} (${contact.type})`;
  };

  return (
    <Autocomplete
      size="medium"
      sx={{ minWidth: 200 }}
      options={residenceContactIds}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField {...params} label="Residence contact" />
      )}
      loading={!data}
      onChange={(_, id) => onChange(id ? residenceContactById[id] : null)}
    />
  );
};
