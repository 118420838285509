import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceManyCount,
  TextField,
} from "react-admin";
import { MarkdownPanel } from "../../layout/MarkdownPanel";
import { RecordDetails } from "../activity-logs/RecordDetails";

export const FirmwareList = () => (
  <List
    perPage={100}
    hasCreate={true}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid
      expand={<MarkdownPanel source="releaseNote" />}
      isRowExpandable={(record) => record.releaseNote}
    >
      <TextField source="version" label="Version" />
      <TextField source="identifier" label="Identifier" />
      <TextField source="blockCount" label="Block Count" />
      <TextField source="shaSum" label="SHA 1" />
      <DateField source="createdAt" label="Creation Date" />
      <ReferenceManyCount
        reference="devices"
        target="firmwareVersion"
        source="version"
        label="Device count"
        link
      />
      <RecordDetails />
      <EditButton />
    </Datagrid>
  </List>
);
