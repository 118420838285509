import Icon from "@mui/icons-material/LocalShipping";
import { ImportAction } from "../../../../actions/ImportAction";
import { ActionsMenu, CreateAction } from "../../../../layout/actions-menu";

export const HousingListActions = () => {
  return (
    <ActionsMenu>
      <CreateAction />
      <ImportAction
        endpoint="/housings/movings"
        icon={<Icon />}
        title="Declare multiple movings"
        templateURL="https://docs.google.com/spreadsheets/d/1Zg7v0CG__lGN9AAjERllLvdDoVCHzhgbLKrj1lDXU8k"
      />
    </ActionsMenu>
  );
};
