import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import NotPossibleIcon from "@mui/icons-material/Warning";
import { Chip, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import type { ReactElement, ReactNode } from "react";
import { CommunicationStatus } from "../type";
import type { Communication } from "../type";

interface CommunicationChipProps {
  communication: Communication | null;
  label?: string;
}
export function CommunicationChip({
  communication,
  label,
}: CommunicationChipProps): ReactNode {
  if (communication === null) {
    return (
      <Tooltip title="Not sent">
        <Chip
          icon={<CloseIcon />}
          label={label ?? "No data"}
          size="small"
          variant="outlined"
          color="warning"
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={`Date : ${dayjs(communication.date).format(
        "DD/MM/YYYY HH:mm"
      )} - ${
        communication.status === CommunicationStatus.FAILURE
          ? communication.statusDetails
          : communication.status
      }`}
    >
      <Chip
        icon={statuses[communication.status].icon}
        label={label ?? communication.medium}
        size="small"
        variant="outlined"
        color={statuses[communication.status].color}
      />
    </Tooltip>
  );
}

const statuses: Record<
  CommunicationStatus,
  {
    color: "success" | "default" | "warning" | "error";
    icon: ReactElement;
  }
> = {
  [CommunicationStatus.SUCCESS]: {
    color: "success",
    icon: <DoneIcon />,
  },
  [CommunicationStatus.PENDING]: {
    color: "default",
    icon: <PendingIcon />,
  },
  [CommunicationStatus.FAILURE]: {
    color: "error",
    icon: <ErrorIcon />,
  },
  [CommunicationStatus.NOT_POSSIBLE]: {
    color: "warning",
    icon: <NotPossibleIcon />,
  },
};
