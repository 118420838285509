import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

interface UpdateActivityLogProps {
  payload: any;
}

export const UpdateActivityLog: React.FC<UpdateActivityLogProps> = ({
  payload,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Before</TableCell>
          <TableCell>After</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <PayloadDiff payload={payload} />
      </TableBody>
    </Table>
  );
};

const PayloadDiff: React.FC<{
  payload: any;
  prefix?: string;
  deep?: number;
}> = ({ payload, prefix = "", deep = 0 }) => {
  if (deep > 10) {
    return (
      <TableRow>
        <TableCell component="th" scope="row">
          {prefix}
        </TableCell>
        <TableCell>Error. Merci de prévenir un dev</TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {Object.entries(payload).map(([key, value]: [string, any]) => {
        if (value == null || typeof value === "string") {
          return null;
        }

        if (Array.isArray(value)) {
          return (
            <TableRow key={key as string}>
              <TableCell component="th" scope="row">
                {prefix}
                {key}
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <pre>
                  {value
                    .filter((v) => v.length === 2)
                    .map((v) =>
                      v.map((e: any) => JSON.stringify(e, null, 2)).join("")
                    )
                    .join("\n")}
                </pre>
              </TableCell>
            </TableRow>
          );
        }

        if (value.__new !== undefined) {
          return (
            <TableRow key={key as string}>
              <TableCell component="th" scope="row">
                {prefix}
                {key as string}
              </TableCell>
              <TableCell>{value.__old}</TableCell>
              <TableCell>{value.__new}</TableCell>
            </TableRow>
          );
        }

        return (
          <PayloadDiff payload={value} deep={deep + 1} prefix={`${key}.`} />
        );
      })}
    </>
  );
};
