import { useGetManyReference, useRecordContext } from "react-admin";
import type { ConfigFrame } from "../../../../frames/config/type";
import type { ConfigAckFrame } from "../../../../frames/config-ack/type";
import type { DataFrame } from "../../../../frames/data/type";
import type { DebugFrame } from "../../../../frames/debug/type";
import type { NetworkFrame } from "../../../../frames/network/type";
import type { ProductionFrame } from "../../../../frames/production/type";
import type { StatusFrame } from "../../../../frames/status/type";

const MAX_POINTS = 2000;

export const useFrameData = (dateRange: { startDate: Date; endDate: Date }) => {
  const record = useRecordContext();

  const config = {
    id: record.deviceId || record.id,
    target: "device_id",
    pagination: { page: 1, perPage: MAX_POINTS },
    sort: { field: "ts", order: "ASC" as const },
    filter: {
      ts: {
        $lt: dateRange.endDate,
        $gt: dateRange.startDate,
      },
    },
  };

  const { data: dataFrames } = useGetManyReference<DataFrame>(
    "data-frames",
    config,
    { staleTime: 1000 * 60 * 5 }
  );

  const { data: configAckFrames } = useGetManyReference<ConfigAckFrame>(
    "config-ack-frames",
    config,
    { staleTime: 1000 * 60 * 5 }
  );

  const { data: statusFrames } = useGetManyReference<StatusFrame>(
    "status-frames",
    config,
    { staleTime: 1000 * 60 * 5 }
  );

  const { data: productionFrames } = useGetManyReference<ProductionFrame>(
    "production-frames",
    config,
    { staleTime: 1000 * 60 * 5 }
  );

  const { data: debugFrames } = useGetManyReference<DebugFrame>(
    "debug-frames",
    config,
    { staleTime: 1000 * 60 * 5 }
  );

  const { data: configFrames } = useGetManyReference<ConfigFrame>(
    "config-frames",
    config,
    { staleTime: 1000 * 60 * 5 }
  );

  const { data: networkFrames } = useGetManyReference<NetworkFrame>(
    "network-frames",
    config,
    { staleTime: 1000 * 60 * 5 }
  );

  return {
    dataFrames,
    configAckFrames,
    statusFrames,
    productionFrames,
    debugFrames,
    configFrames,
    networkFrames,
  };
};
