import { get } from "lodash";
import { FunctionField } from "react-admin";
import type { FunctionFieldProps } from "react-admin";
import type { Except } from "type-fest";

interface EnumFieldProps {
  colors: Record<string, string>;
  source: string;
  defaultColor?: string;
  label?: string;
  labels?: Record<string, string>;
  emptyText?: string;
}

export const EnumField: React.FC<
  EnumFieldProps & Except<FunctionFieldProps, "render">
> = ({ colors, source, defaultColor, labels, emptyText, ...props }) => {
  return (
    <FunctionField
      {...props}
      render={(record: any) => {
        if (!record) return null;
        const value = get(record, source);
        if (emptyText && !value) return emptyText;
        return (
          <div
            style={{
              backgroundColor: (colors[value] || defaultColor) + "52",
              color: colors[value] || defaultColor,
              borderRadius: 5,
              padding: `2px 5px`,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            {labels ? labels[value] : value}
          </div>
        );
      }}
    />
  );
};
