import { History } from "@mui/icons-material";
import type { IconButtonProps } from "@mui/material";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import type { ReactNode } from "react";
import { useState } from "react";
import {
  Datagrid,
  DateField,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from "react-admin";

interface HistoryProps {
  attribute: string;
  children: ReactNode;
}

export function AttributeHistory({
  attribute,
  children,
  ...buttonProps
}: HistoryProps & IconButtonProps) {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <IconButton onClick={() => setIsOpened(true)} {...buttonProps}>
        <History fontSize="inherit" />
      </IconButton>
      <Dialog open={isOpened} onClose={() => setIsOpened(false)} maxWidth="md">
        <DialogContent>
          <ReferenceManyField
            reference="attribute-history"
            target="recordId"
            label={false}
            filter={{ attribute }}
            sort={{ field: "ts", order: "DESC" }}
          >
            <Datagrid bulkActionButtons={false}>
              <DateField label="Date" source="ts" showTime />
              {children}
              <TextField source="source" />
              <ReferenceField
                label="Changed by"
                reference="users"
                source="userId"
                link={false}
              />
            </Datagrid>
          </ReferenceManyField>
        </DialogContent>
      </Dialog>
    </>
  );
}

export enum AttributeHistorySource {
  Unknown = "unknown",
  BackOffice = "back office",
  Intervention = "intervention",
  LandlordApp = "landlord app",
  ReservationApp = "reservation app",
  ListingLocataire = "landlord listing",
  MovingBlukImport = "moving bulk import",
  ZendeskWebhook = "zendesk webhook",
}

export interface AttributeHistoryData {
  ts: Date;
  recordId: string;
  resource: string;
  attribute: string;
  value: string | number | null | undefined | Date | object;
  source: AttributeHistorySource;
  userId?: string;
}
