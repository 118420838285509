import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import type { ReactNode } from "react";
import { Button, Confirm, useRecordContext } from "react-admin";
import { useMutation, useQuery } from "react-query";
import { dataProvider } from "../../../../../providers/data";
import type { Project } from "../../../type";

export function ProjectStagingTest(): ReactNode {
  const project = useRecordContext<Project>();
  const [confirmCommunications, setConfirmCommunications] = useState(false);

  const { data, error: errorInstallationAppointment } = useQuery(
    ["project", "InstallationAppointmentEmails", { id: project.id }],
    () => dataProvider.getInstallationAppointmentCommunications(project.id),
    { retry: false }
  );
  const sendCommunicationTest = useMutation({
    mutationFn: () =>
      dataProvider.sendInstallationAppointmentCommunications(project.id),
  });

  return (
    <Grid item xs={12} md={6}>
      <Button
        label="Send Installation Appointment Communications"
        type="button"
        disabled={
          !!errorInstallationAppointment || !data || data.emails?.length === 0
        }
        onClick={() => {
          setConfirmCommunications(true);
        }}
      ></Button>
      <Typography variant="caption" color="error">
        {errorInstallationAppointment
          ? (errorInstallationAppointment as any)?.message ??
            "Failed to get emails to send"
          : data && data.emails?.length === 0
          ? "No emails to send"
          : undefined}
      </Typography>

      {confirmCommunications && (
        <Confirm
          isOpen={confirmCommunications}
          title="Send Installation Appointment Communications Confirmation"
          content={
            <>
              <p>
                Please check the listing before sending the communication tests.
                Emails and SMS might be sent to individuals outside of Elax if
                the provided contact details are not verified.
              </p>
              <ul>
                {data?.emails?.map((email) => (
                  <li key={email}>{email}</li>
                ))}
                {data?.phoneNumbers?.map((phoneNumber) => (
                  <li key={phoneNumber}>{phoneNumber}</li>
                ))}
              </ul>
            </>
          }
          onConfirm={() => {
            sendCommunicationTest.mutate();
            setConfirmCommunications(false);
          }}
          onClose={() => setConfirmCommunications(false)}
        />
      )}
    </Grid>
  );
}
