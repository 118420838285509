import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceManyCount,
  ShowButton,
  TextField,
} from "react-admin";

export const GroupList = () => (
  <List perPage={100} hasCreate={true}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="groupName" label="name" />
      <TextField source="groupDescription" label="description" />
      <ReferenceField source="groupOwner" reference="users" label="owner" />
      <ReferenceManyCount reference="devices" target="group" label="devices" />
      <DateField source="createdAt" label="creation date" />
      <ShowButton />
    </Datagrid>
  </List>
);
