import Autocomplete from "@mui/material/Autocomplete";
import type { AutocompleteProps } from "@mui/material/Autocomplete";
import MuiTextField from "@mui/material/TextField";
import { useGetList } from "react-admin";
import type { SortPayload } from "react-admin";
import type { Except } from "type-fest";

export const ResourceSelect: React.FC<
  Except<
    AutocompleteProps<any, any, any, any>,
    "renderInput" | "options" | "loading" | "onChange"
  > & {
    resource: string;
    label: string;
    onChange: (value: any) => void;
    perPage?: number;
    sort?: SortPayload;
    labelField: string;
    valueField?: string;
  }
> = ({
  resource,
  label,
  perPage,
  sort,
  onChange,
  labelField,
  valueField,
  ...props
}) => {
  const { data, isLoading } = useGetList(resource, {
    pagination: { page: 1, perPage: perPage || 1000 },
    sort: sort || { field: "id", order: "DESC" },
  });

  return (
    <Autocomplete
      {...props}
      options={(data || []).map((record) => ({
        label: record[labelField],
        id: record[valueField ?? "id"],
      }))}
      loading={isLoading}
      renderInput={(params) => <MuiTextField {...params} label={label} />}
      onChange={(_, value) => onChange(value.id)}
    />
  );
};
