import BackIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import NextIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ZoomOut from "@mui/icons-material/ZoomOut";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import { Button } from "react-admin";
import { useDateRangeFilter } from "../../../../../filters/useDateRangeFilter";
import { DateRangePicker } from "./DateRangePicker";
import { useDevicePeriods } from "./useReferencePeriod";

export const DateRangeFilter: React.FC = () => {
  const { dateRange, setDateRange, zoomOut, previous, next, isMax } =
    useDateRangeFilter();
  const { reference, initialization } = useDevicePeriods();

  return (
    <>
      <DateRangePicker
        range={dateRange}
        onChange={setDateRange}
        referencePeriod={reference}
      />
      <IconButton onClick={previous}>
        <BackIcon />
      </IconButton>
      <IconButton onClick={zoomOut}>
        <ZoomOut />
      </IconButton>
      <IconButton onClick={next} disabled={isMax}>
        <NextIcon />
      </IconButton>
      {initialization && (
        <Button
          label="Factory"
          variant="outlined"
          color="warning"
          sx={{ marginRight: 1 }}
          onClick={() => {
            const size = dayjs(dateRange.endDate).diff(
              dateRange.startDate,
              "seconds"
            );
            setDateRange({
              startDate: dayjs(initialization)
                .subtract(size / 2, "seconds")
                .toDate(),
              endDate: dayjs(initialization)
                .add(size / 2, "seconds")
                .toDate(),
            });
          }}
        />
      )}
      {reference.startDate && (
        <Button
          label="Observation"
          variant="outlined"
          color="error"
          sx={{ marginRight: 1 }}
          onClick={() => {
            const size = dayjs(dateRange.endDate).diff(
              dateRange.startDate,
              "seconds"
            );
            setDateRange({
              startDate: dayjs(reference.startDate)
                .subtract(size / 2, "seconds")
                .toDate(),
              endDate: dayjs(reference.startDate)
                .add(size / 2, "seconds")
                .toDate(),
            });
          }}
        />
      )}
      {reference.endDate && (
        <Button
          label="Pilot"
          variant="outlined"
          color="primary"
          onClick={() => {
            const size = dayjs(dateRange.endDate).diff(
              dateRange.startDate,
              "seconds"
            );
            setDateRange({
              startDate: dayjs(reference.endDate)
                .subtract(size / 2, "seconds")
                .toDate(),
              endDate: dayjs(reference.endDate)
                .add(size / 2, "seconds")
                .toDate(),
            });
          }}
        />
      )}
    </>
  );
};
