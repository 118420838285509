import { Paper } from "@mui/material";
import dayjs from "dayjs";
import type Highcharts from "highcharts";
import { range } from "lodash";
import { useMemo } from "react";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import { useIncidentsOverTime } from "../../../providers/data-hooks/useIncidentsOverTime";
import { codeToLabel } from "../../../resources/alerts/fields/AlertCodeField";
import { Chart } from "../../Chart";

const DAY_COUNT = 60;

export const IncidentsOverTime: React.FC = () => {
  const [filter] = useQueryParam("filter", withDefault(JsonParam, {}));
  const data = useIncidentsOverTime({
    ...filter,
    createdAt: {
      $gte: dayjs().subtract(DAY_COUNT, "days").format("YYYY-MM-DD"),
    },
  });

  const days = useMemo(
    () =>
      range(DAY_COUNT - 1, -1, -1).map((i) =>
        dayjs().subtract(i, "days").format("YYYY-MM-DD")
      ),
    []
  );

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      height: 150,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      categories: days,
      labels: { enabled: false },
      crosshair: true,
      minPadding: 1,
      maxPadding: 1,
    },
    yAxis: {
      min: 0,
      tickAmount: 4,
      title: {
        text: undefined,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderRadius: 0,
      },
    },
    series: Object.entries(data ?? {}).map(([type, data]) => ({
      name: codeToLabel[type]?.label ?? type,
      type: "column",
      color: codeToLabel[type]?.color,
      data: days.map((day) => ({ y: data[day] ?? 0 })),
      stack: true,
      groupPadding: 0,
    })) as any,
  };

  return (
    <Paper elevation={1} sx={{ position: "relative", paddingTop: 1 }}>
      <Chart options={options} isLoading={!data} />
    </Paper>
  );
};
