import { Box, Chip, Grid } from "@mui/material";
import {
  FunctionField,
  NumberField,
  ReferenceArrayField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useNotify,
  useRefresh,
} from "react-admin";
import { ExportPDFAction } from "../../../actions/PDFReport";
import { PipedriveLink } from "../../../fields/PipedriveLink";
import { InlineLabel } from "../../../layout/InlineLabel";
import { ActionsMenu, EditAction } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";
import { ClientCategory } from "../fields/ClientCategory";
import type { Organization } from "../types";
import { AddSftpUser } from "./actions/AddSftpUser";
import { InviteToDashboard } from "./actions/InviteToDashboard";
import { Communications } from "./tabs/Communications";
import { ClientDashboard } from "./tabs/Dashboard";
import { ClientDetails } from "./tabs/Details";
import { Savings } from "./tabs/Savings";

export const OrganizationShow = () => {
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Show actions={<OrganizationActions />}>
      <Grid container margin={2}>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box marginRight={2}>
            <ClientCategory fontSize="large" />
          </Box>
          <TextField source="name" variant="h4" />
          <Box marginLeft={2}>
            <PipedriveLink
              size={25}
              source="pipedriveOrganizationId"
              resource="organization"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <InlineLabel label="Heater Count">
            <TextField source="heaterCount" variant="body1" />
          </InlineLabel>
          <InlineLabel label="Imported Housing Count">
            <NumberField source="stats.housingCount" />
          </InlineLabel>
          <InlineLabel label="Installed Devices Count">
            <NumberField source="stats.deviceCount" />
          </InlineLabel>
          <InlineLabel label="Dashboard Admins">
            <ReferenceArrayField reference="users" source="dashboardAdmins" />
          </InlineLabel>
          <InlineLabel label="Dashboard Viewers">
            <ReferenceArrayField reference="users" source="dashboardViewers" />
          </InlineLabel>
          <InlineLabel label="SFTP users">
            <FunctionField<Organization>
              render={(record) => (
                <>
                  {(record.sftpUsers ?? []).map((username) => (
                    <Chip
                      size="small"
                      sx={{ margin: 1 }}
                      label={username}
                      key={username}
                      onDelete={async () => {
                        try {
                          await dataProvider.removeSftpUser(
                            record.id,
                            username
                          );
                          refresh();
                        } catch (error: any) {
                          notify(
                            `Failed to remove sftp user: ${error.message}`,
                            {
                              type: "error",
                            }
                          );
                        }
                      }}
                    />
                  ))}
                </>
              )}
            />
          </InlineLabel>
        </Grid>
      </Grid>
      <TabbedShowLayout>
        <Tab label="Details">
          <ClientDetails />
        </Tab>
        <Tab label="Dashboard">
          <ClientDashboard />
        </Tab>
        <Tab label="Savings">
          <Savings />
        </Tab>
        <Tab label="Communications">
          <Communications />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const OrganizationActions = () => {
  return (
    <ActionsMenu>
      <EditAction />
      <InviteToDashboard />
      <AddSftpUser />
      <ExportPDFAction />
    </ActionsMenu>
  );
};
