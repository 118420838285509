import { Divider } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { ConsumptionExport } from "../../../actions/ConsumptionExport";
import { ActionsMenu } from "../../../layout/actions-menu";
import { DeviceTagIcon } from "../../device-tags";
import { ExportDevicesOrEndUsers } from "../../end-users/actions/Export";
import { GroupIcon } from "../../group";
import { ExportDeviceFrames } from "../show/actions/ExportDeviceFrames";
import { AssignFirmware } from "./AssignFirmware";
import { EditDevicesButton } from "./BulkEditDevices";
import { ChangePilotConfig } from "./edit-device-config";

export const DeviceBulkActionButtons = () => (
  <>
    <ActionsMenu>
      <ExportDevicesOrEndUsers resource="devices" />
      <ExportDeviceFrames />
      <IfCanAccess resource="devices.reports" action="read">
        <ConsumptionExport />
      </IfCanAccess>
      <Divider />
      <EditDevicesButton
        resource="groups"
        source="group"
        label="Groups"
        icon={<GroupIcon />}
      />
      <EditDevicesButton
        resource="device-tags"
        source="tag"
        label="Tags"
        icon={<DeviceTagIcon />}
      />
      <ChangePilotConfig />
      <AssignFirmware />
    </ActionsMenu>
  </>
);
