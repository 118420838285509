import { Grid } from "@mui/material";
import { uniq } from "lodash";
import {
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { residenceContactTypes } from "./types";

export const ResidenceContactEditionForm: React.FC = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceArrayInput reference="residences" source="residences" />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            source="type"
            choices={(record.type
              ? uniq([record.type, ...residenceContactTypes]) // to be able to edit contacts with outdated "type" value
              : residenceContactTypes
            ).map((type) => ({
              id: type,
              name: type,
            }))}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput label="Job title" source="jobTitle" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="First Name" source="firstName" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Last Name" source="lastName" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Email" source="email" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Phone" source="phone" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Notes"
            source="notes"
            fullWidth
            multiline
            minRows={8}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
