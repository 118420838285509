import { mapValues } from "lodash";
import { EnumField } from "../../../fields/EnumField";
import { communicationTopicConfigs } from "../type";

interface CommunicationTopicFieldProps {
  label?: string;
}

export function CommunicationTopicField({
  label,
}: CommunicationTopicFieldProps) {
  return (
    <EnumField
      label={label || "Topic"}
      source="topic"
      colors={mapValues(communicationTopicConfigs, (v) => v.color["400"])}
      labels={mapValues(communicationTopicConfigs, (v) => v.label)}
    />
  );
}
