import Dialog from "@mui/material/Dialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import { useState } from "react";
import {
  Button,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";

export const MuteButton: React.FC<{ label?: string }> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleItemClick = (mutedUntil?: Date) => {
    update("alerts", {
      id: record.id,
      data: { mutedUntil },
      previousData: record,
    });
    handleClose();
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();

  const [update, { isLoading }] = useUpdate(
    "alerts",
    {},
    {
      onSuccess: () => {
        notify("Alert muted");
        refresh();
      },
      onError: (error: any) => {
        notify(`Failed to mute alert: ${error.message}`, {
          type: "error",
        });
      },
    }
  );
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);

  return (
    <>
      <Button onClick={handleClick} label="Mute" disabled={isLoading} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() =>
            handleItemClick(dayjs().add(1, "day").startOf("day").toDate())
          }
        >
          1 day
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleItemClick(dayjs().add(1, "week").startOf("day").toDate())
          }
        >
          1 week
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleItemClick(dayjs().add(1, "month").startOf("day").toDate())
          }
        >
          1 month
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleItemClick(dayjs().add(6, "months").startOf("day").toDate())
          }
        >
          6 month
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleItemClick(dayjs().add(10, "years").startOf("day").toDate())
          }
        >
          10 years
        </MenuItem>
        <MenuItem onClick={() => setIsDatePickerOpened(true)}>
          Custom...
        </MenuItem>
      </Menu>
      <Dialog
        onClose={() => setIsDatePickerOpened(false)}
        open={isDatePickerOpened}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={null as Date | null}
            shouldDisableDate={(date) => date < new Date()}
            onChange={(newValue) => {
              if (newValue) {
                handleItemClick(dayjs(newValue).startOf("day").toDate());
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Dialog>
    </>
  );
};
