import { Datagrid, List, ShowButton, TextField, WithRecord } from "react-admin";
import { ActionsMenu, CreateAction } from "../../layout/actions-menu";
import type { DemandResponseEntity } from "./type";

export const DemandResponseEntityList = () => {
  return (
    <List
      perPage={100}
      filter={{ name: { $exists: true } }}
      actions={<DemandResponseEntityActions />}
      hasCreate
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="maxCapacityInMW" label="Max capacity (MW)" />
        <TextField source="method" />
        <WithRecord<DemandResponseEntity>
          render={(record) => record.pdls.length}
          label="#PDL"
        />
        <TextField source="isTest" label="Test entity" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const DemandResponseEntityActions = () => (
  <ActionsMenu>
    <CreateAction />
  </ActionsMenu>
);
