import { Typography } from "@mui/material";
import {
  CalendarPicker,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { keyBy } from "lodash";
import type { ReactNode } from "react";
import { useGetList } from "react-admin";

export function MuiDateProvider({
  children,
}: {
  children: ReactNode;
}): ReactNode {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      {children}
    </LocalizationProvider>
  );
}

export const TempoCalendar = () => {
  const { data } = useGetList("tempo-calendar", {
    pagination: { page: 1, perPage: 1000 },
  });

  const days = keyBy(data, (d) => d.day) as any;

  return (
    <MuiDateProvider>
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Tempo Calendar
      </Typography>
      <CalendarPicker
        readOnly
        onChange={() => {}}
        date={dayjs()}
        renderDay={(day) => (
          <PickersDay
            day={day}
            outsideCurrentMonth={false}
            onDaySelect={() => {}}
            disabled
            sx={{
              backgroundColor: colors[
                days[day.format("YYYY-MM-DD")]?.color || "UNKNOWN"
              ] as any,
            }}
          />
        )}
      />
    </MuiDateProvider>
  );
};

const colors: any = {
  BLUE: "#3f51b5b0",
  WHITE: "#9e9e9e4d",
  RED: "#f44336b0",
  UNKNOWN: "transparent",
};
