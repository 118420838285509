import { Button, CardContent, TextField } from "@mui/material";
import { useState } from "react";
import { Login, useRedirect } from "react-admin";
import { useMutation } from "react-query";
import { StringParam, useQueryParam } from "use-query-params";
import { setToken } from "../../providers/auth";
import { dataProvider } from "../../providers/data";

export const SetPassword = () => {
  const [token] = useQueryParam("token", StringParam);
  const redirect = useRedirect();

  const [password, setPassword] = useState("");
  const mutation = useMutation({
    mutationFn: async () => {
      if (!token) {
        return;
      }
      setToken(token);
      const { data } = await dataProvider.setPassword(password);
      setToken(data.token);
      redirect("/");
    },
  });

  return (
    <Login>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          type="password"
          label="Password"
          size="small"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ marginY: 2 }}
          onClick={() => {
            mutation.mutate();
          }}
          disabled={mutation.isLoading}
        >
          Save your password
        </Button>
      </CardContent>
    </Login>
  );
};
