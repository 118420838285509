import {
  Datagrid,
  DateField,
  List,
  RecordContextProvider,
  ReferenceField,
  TextField,
  WithRecord,
  useRecordContext,
} from "react-admin";
import { NameField } from "../end-users/fields/NameField";
import { ShowActivityLog } from "./show/Show";

export const ActivityLogList = () => {
  return (
    <List perPage={100} sort={{ field: "createdAt", order: "DESC" }}>
      <Datagrid bulkActionButtons={false} expand={<ShowActivityLog />}>
        <DateField label="Date" source="createdAt" showTime />
        <ReferenceField label="User" source="userId" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="action" />
        <TextField source="resource" />
        <WithRecord
          label="Link"
          render={(record) => {
            if (record.action === "delete") {
              return (
                <RecordContextProvider value={record.payload.previousData}>
                  <ResourceRepresentation resource={record.resource} />
                </RecordContextProvider>
              );
            }
            return (
              <ReferenceField
                reference={record.resource}
                source="recordId"
                link="show"
              >
                <ResourceRepresentation resource={record.resource} />
              </ReferenceField>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

const ResourceRepresentation: React.FC<{ resource: string }> = ({
  resource,
}) => {
  const record = useRecordContext();

  if (resource === "device-configs") {
    return (
      <ReferenceField
        reference="devices"
        source="deviceId"
        link={`/devices/${record.deviceId}/show/pilot`}
      >
        <TextField source="name" />
      </ReferenceField>
    );
  }

  if (resource === "devices") {
    return <TextField source="name" />;
  }

  if (resource === "users") {
    return <TextField source="email" />;
  }

  if (resource === "projects") {
    return <TextField source="name" />;
  }

  if (resource === "interventions") {
    return (
      <>
        <TextField source="type" />
        {" - "}
        <TextField source="status" />
        {" - "}
        <ReferenceField reference="projects" source="projectId" link={false}>
          <TextField source="name" />
        </ReferenceField>
        {" - "}
        <TextField source="unitId" />
      </>
    );
  }

  if (resource === "end-users") {
    return <NameField />;
  }

  if (resource === "groups") {
    return <TextField source="groupName" />;
  }

  return <TextField source="id" />;
};
