import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import type { SxProps } from "@mui/material";
import { CreateDialog } from "@react-admin/ra-form-layout";
import { useState } from "react";
import { Link, SimpleForm, TextInput, useGetList } from "react-admin";
import type { ListItem } from "../resources/list-item/types";

interface EditableListFieldProps {
  label: string;
  value: string;
  listId: string;
  itemName?: {
    singular: string;
    plural: string;
    article: "a" | "an";
  };
  onChange: (newValue: string) => void;
  sx?: SxProps;
  required?: boolean;
}

export const EditableListField: React.FC<EditableListFieldProps> = ({
  label,
  value,
  listId,
  itemName,
  onChange,
  sx,
  required,
}) => {
  const { data: listItems, isLoading } = useGetList<ListItem>("list-items", {
    pagination: { page: 1, perPage: 1000 },
    filter: { listId },
  });
  const [showCreate, setShowCreate] = useState(false);

  return (
    <>
      <FormControl size="small" fullWidth sx={sx}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          fullWidth
          value={value}
          onChange={(event) => onChange(event.target.value)}
          size="small"
          required={required}
        >
          {!isLoading &&
            listItems?.map((listItem) => (
              <MenuItem
                key={listItem.value}
                value={listItem.value}
                sx={{ whiteSpace: "normal" }}
              >
                {listItem.label ?? listItem.value}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText sx={{ color: "blue" }}>
          <Stack direction="row" justifyContent="space-between">
            <Box
              onClick={() => setShowCreate(true)}
              sx={{ color: "#1976d2", cursor: "pointer" }}
            >
              ➕ add {itemName?.article ?? "an"} {itemName?.singular ?? "item"}
            </Box>
            <Link to="/list-items">✏️ edit {itemName?.plural ?? "items"}</Link>
          </Stack>
        </FormHelperText>
      </FormControl>
      <CreateDialog
        title={`Add ${itemName?.article ?? "an"} ${
          itemName?.singular ?? "item"
        }`}
        resource="list-items"
        record={{ listId }}
        isOpen={showCreate}
        close={() => setShowCreate(false)}
        redirect={false}
      >
        <SimpleForm>
          <Box sx={{ minWidth: 300 }}>
            <TextInput label="Value" source="value" fullWidth required />
          </Box>
          <Box sx={{ minWidth: 300 }}>
            <TextInput label="Label" source="label" fullWidth required />
          </Box>
        </SimpleForm>
      </CreateDialog>
    </>
  );
};
