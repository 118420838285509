import MoreIcon from "@mui/icons-material/MoreHoriz";
import {
  Dialog,
  Button as DialogButton,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import type { ButtonProps, DialogProps, IconButtonProps } from "@mui/material";
import type { ComponentProps, FunctionComponent, ReactNode } from "react";
import { createStateContext } from "react-use";
import type { Except } from "type-fest";
import { useCloseMenu } from "./context";

function withDialogContext<C extends FunctionComponent<any>>(Component: C) {
  const WithDialogContext = function (props: ComponentProps<C>) {
    return (
      <ActionDialogStateProvider>
        <Component {...props} />
      </ActionDialogStateProvider>
    );
  };
  WithDialogContext.displayName = `withDialogContext(${Component.displayName})`;
  return WithDialogContext;
}

const [useActionDialogState, ActionDialogStateProvider] =
  createStateContext<boolean>(false);

interface ActionMenuItemProps {
  label: string;
  icon: ReactNode;
  dialog: ReactNode;
  disabled?: boolean;
}

export const DialogActionMenuItem: React.FC<ActionMenuItemProps> =
  withDialogContext(({ label, icon, dialog, disabled }) => {
    const [, setOpen] = useActionDialogState();

    return (
      <>
        <MenuItem onClick={() => setOpen(true)} disabled={disabled}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
          <MoreIcon color="action" sx={{ marginLeft: 3 }} />
        </MenuItem>
        {dialog}
      </>
    );
  });

export const DialogActionIconButton: React.FC<
  Except<IconButtonProps, "onClick"> & { dialog: ReactNode }
> = withDialogContext(({ dialog, ...props }) => {
  const [, setOpen] = useActionDialogState();

  return (
    <>
      <IconButton {...props} onClick={() => setOpen(true)} />
      {dialog}
    </>
  );
});

export const ActionDialog = (props: Except<DialogProps, "open">) => {
  const [open, setOpen] = useActionDialogState();
  const closeMenu = useCloseMenu();

  return (
    <Dialog
      {...props}
      open={open}
      onClose={(...args) => {
        setOpen(false);
        if (props.onClose) {
          props.onClose(...args);
        }
        closeMenu();
      }}
    ></Dialog>
  );
};

export const ActionDialogButton: React.FC<
  Except<ButtonProps, "onClick"> & {
    onClick?: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      close: () => void
    ) => void;
  }
> = ({ onClick, ...props }) => {
  const [, setOpen] = useActionDialogState();
  const closeMenu = useCloseMenu();

  const close = () => {
    setOpen(false);
    closeMenu();
  };
  return (
    <DialogButton
      {...props}
      onClick={async (event) => {
        if (onClick) {
          onClick(event, close);
        } else {
          close();
        }
      }}
    />
  );
};
