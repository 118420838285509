import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Star";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Portal,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import type { ListItemProps } from "@mui/material";
import type { MouseEventHandler, ReactNode } from "react";
import { useState } from "react";
import {
  Link,
  useCreatePath,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import {
  FAVORITE_INFO_LIST_ID,
  useFavorite,
  useFavoritesReady,
} from "./favorite/FavoritesInfoList";

interface InfoListProps {
  label: string;
  children?: ReactNode;
  id?: string;
  secondaryAction?: ReactNode;
}
export default function InfoList({
  label,
  children,
  id,
  secondaryAction,
}: InfoListProps) {
  return (
    <Paper
      sx={{
        paddingRight: 2,
        paddingLeft: 2,
        marginTop: 1,
      }}
    >
      <List
        id={id}
        subheader={
          <Stack position="relative">
            <Typography
              variant="button"
              padding={1.5}
              textAlign="center"
              display="block"
            >
              {label}
            </Typography>
            <Stack
              position="absolute"
              right={0}
              top={0}
              bottom={0}
              justifyContent="center"
            >
              {secondaryAction}
            </Stack>
          </Stack>
        }
      >
        <Divider />
        {children}
      </List>
    </Paper>
  );
}

type ItemProps = {
  label: string;
  children?: ReactNode;
} & ListItemProps;

const Item = ({ label, children, ...props }: ItemProps) => {
  const [isFavorite, setFavorite] = useFavorite(label);
  const [isReady] = useFavoritesReady();

  return (
    <Portal
      container={document.getElementById(FAVORITE_INFO_LIST_ID)}
      disablePortal={!isFavorite || !isReady}
    >
      <ListItem disableGutters disablePadding {...props}>
        <ListItemText
          primary={
            <>
              {label}
              <IconButton
                sx={{ padding: 0, top: -2 }}
                size="small"
                aria-label="add to favorite"
                color={isFavorite ? "warning" : "default"}
                onClick={() => {
                  setFavorite((value) => !value);
                }}
              >
                <FavoriteIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </>
          }
          primaryTypographyProps={{
            variant: "overline",
            lineHeight: 1.5,
            fontWeight: "bold",
            color: colors.grey[700],
          }}
          secondary={children}
        />
      </ListItem>
    </Portal>
  );
};

const stopPropagation: MouseEventHandler = (e) => e.stopPropagation();

const ItemWithLink = ({
  link,
  children,
  ...props
}: ItemProps & {
  link: "show" | "edit";
}) => {
  const createPath = useCreatePath();
  const record = useRecordContext();
  const resource = useResourceContext(props);

  const url = createPath({
    resource,
    id: record.id,
    type: link,
  });

  return (
    <Item {...props}>
      <Link to={url} onClick={stopPropagation}>
        {children}
      </Link>
    </Item>
  );
};

InfoList.Item = Item;
InfoList.ItemWithLink = ItemWithLink;

const Collapsible = ({ children }: { children: ReactNode }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      {/* children needs to be in the dom so that portal works for favorite items */}
      {collapsed ? <div style={{ display: "none" }}>{children}</div> : children}
      {collapsed ? (
        <Button
          onClick={() => setCollapsed(false)}
          startIcon={<ExpandMore />}
          size="small"
          fullWidth
        >
          More
        </Button>
      ) : (
        <Button
          onClick={() => setCollapsed(true)}
          startIcon={<ExpandLess />}
          size="small"
          fullWidth
        >
          Less
        </Button>
      )}
    </>
  );
};

InfoList.Collapsible = Collapsible;
